<template>
    <div>
        <div v-if="errorMessage" class="alert alert-danger"><i class="fa fa-triangle-exclamation mx-2"></i>{{ errorMessage
        }}</div>
        <div v-if="successMessage" class="alert alert-success"><i class="fa fa-circle-check mx-2"></i>{{ successMessage }}
        </div>
        <div class="d-flex flex-row flex-wrap">
            <draggable drag-class="dragClass" :options="{ forceFallback: true }" :list="localPartAttributeGroup"
                group="groups" class="d-flex flex-wrap" @change="updateGroupOrder">
                <div v-for="group in localPartAttributeGroup" :key="group.name" class="card m-2 dragItem">
                    <i class="fa fa-up-down-left-right d-flex flex-row-reverse m-2"></i>
                    <div>
                        <div class="card-body d-flex flex-column">
                            <h5 class="card-title">{{ group.title }}</h5>
                            <p class="card-text" id="attribute_description">{{ group.description }}</p>
                            <div class="card">
                                <members :group="group" :can-drag="canDrag" @message="showChildMessage"></members>
                            </div>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
        <div v-if="orderMessage" class="alert alert-info mx-5"><i class="fa fa-circle-info mx-2"></i>{{ orderMessage }}
            <button class="btn btn-primary mx-5" style="height: 35px;" @click="saveGroupOrder"><i
                    class="fa fa-floppy-disk mx-2"></i>Save Group Order</button>
        </div>
    </div>
</template>
<style>
.dragClass {
    opacity: 1 !important;
    box-shadow: 0 .75em 2em rgba(0, 0, 0, .5), 0 .75em 3em rgba(0, 0, 0, .15);
}

.dragItem {
    width: 18rem;
    position: relative;
    cursor: grab;
}

.dragItem:active {
    cursor: grabbing;
}
</style>
<script>
import draggable from "vuedraggable";
import PartAttributesMembers from './PartAttributesMembers.vue';
import axios from 'axios';
export default {
    props: {
        partAttributeGroup: Array,
        canDrag: Boolean,
    },
    components: {
        draggable,
        'members': PartAttributesMembers,
    },
    data() {
        return {
            groups: [],
            localPartAttributeGroup: [],
            newGroupNewMembers: [],
            groupsSortOrder: [],
            errorMessage: "",
            successMessage: "",
            orderMessage: "",
        }
    },
    watch: {
        partAttributeGroup: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.localPartAttributeGroup = JSON.parse(JSON.stringify(newVal));
            }
        },
    },
    methods: {
        showAlert(message, type) {
            this[type] = message;
            setTimeout(() => {
                this[type] = '';
            }, 2000); 
        },
        alertsTimer() {
            this.showAlert('Group order updated', 'successMessage');
            this.showAlert('Error: ' + error, 'errorMessage');
        },
        saveGroupOrder() {
            this.orderMessage = null;

            // Update sort_order based on array index + 1
            this.localPartAttributeGroup.forEach((group, index) => {
                group.sort_order = index + 1;
            });

            // Prepare the data you want to send
            const data = {
                localPartAttributeGroup: this.localPartAttributeGroup
            };

            // Send a POST request to your server
            axios.post('/part-attributes/group/update', data)
                .then(response => {
                    if (response.data.error) {
                        this.localPartAttributeGroup = JSON.parse(JSON.stringify(this.partAttributeGroup));
                        this.showAlert(response.data.error, 'errorMessage');
                    } else {
                        this.showAlert('Group order updated', 'successMessage');
                    }
                })
                .catch(error => {
                    this.localPartAttributeGroup = JSON.parse(JSON.stringify(this.partAttributeGroup));
                    this.showAlert('Error: ' + error, 'errorMessage');
                });
        },
        updateGroupOrder(event) {
            const movedElement = event.moved.element;
            //Indexs start at 0 so lets add 1
            const oldIndex = event.moved.oldIndex + 1;
            const newIndex = event.moved.newIndex + 1;
            //now lets check if the sort order matches the index
            if (newIndex != movedElement.sort_order) {
                const foundGroup = this.groupsSortOrder.find(group => group.id === movedElement.id);
                if (foundGroup) {
                    if (newIndex != foundGroup.sort_order) {
                        this.orderMessage = "Group order has change to save the new order "
                    }
                }
            }
        },
        showChildMessage(message){
            if(message[0] == 'successMessage'){
                this.showAlert(message[1],message[0]);
            }
            if(message[0] == 'Error' | message[0] == 'errorMessage'){
                this.showAlert(message[1],message[0]);
            }
        }
    },
    mounted() {

        const extractedData = this.localPartAttributeGroup.map(group => ({
            id: group.id,
            sort_order: group.sort_order
        }));
        this.groupsSortOrder = extractedData;
    },
}
</script>