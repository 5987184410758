<template>
  <div>
    <ul class="nav nav-pills nav-side flex-column">
      <li class="nav-item">
        <a href="/dashboard" class="nav-link">
          <i class="fa fa-dashboard"></i> Dashboard <i class="fa fa-gear"></i>
        </a>
      </li>
    </ul>
    <ul class="nav nav-pills nav-side flex-column">
      <li v-for="item in navItems" :key="item.name" class="nav-item">
        <a :class="getNavClass(item.route)" :href="item.route">
          <i :class="item.icon"></i> {{ item.name }}
        </a>
      </li>
    </ul>
    <ul class="nav nav-pills nav-side flex-column">
      <li class="side-limnocity-nav-item">
        <a href="/partforge">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#ccb17a" version="1.1" id="Layer_1" width="20px" height="20px"
            viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
            <path
              d="M248,91.3V67H80v8H9c0,0,10.7,40.6,67.3,40.6c30.3,0,34.4,12.7,34.4,19.1c0,8.4-5.1,21.9-36.7,32.8V191h38.7
                          c6.8-5.2,15.3-8.2,24.5-8.2s17.7,3.1,24.5,8.2H201c0,0,0-15.1,0-22.9c-23.4-7.7-38.7-20.4-38.7-34.8
                          C162.3,110.6,200.1,92.5,248,91.3z M80,87c-52,0-52-4-52-4h52C80,83,80,85.4,80,87z M88,79v-4h152v4H88z" />
          </svg> Part Forge
        </a>
        <a href="/product-groups"><i class="fa fa-solid fa-layer-group mx-1"></i> Product
          Groups</a>
        <div v-if="showAdminDropdown" class="dropdown-menu nested-dropdown">
          <a href="/part-attributes"><i class="fa fa-car-battery mx-1 my-1"></i> Part
            Attributes</a>
          <a href="/databrowser" class="dropdown-item"><i class="fa fa-database"></i> Data Browser</a>
          <a href="/uom-priorites" class="dropdown-item"><i class="fa fa-ruler-combined"></i> UOM
            Priorities</a>
          <a href="/fileupload" class="dropdown-item"><i class="fa fa-solid fa-upload"></i> Fileupload</a>
          <a href="/users" class="dropdown-item"><i class="fa fa-users"></i> Users</a>
          <a href="/roles" class="dropdown-item"><i class="fa fa-user-shield"></i> Roles</a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tenantDashboard: String,
    tenantName: String,
    permissions: String,
    routes: String,
    icons: String,
  },
  data() {
    return {
      parsedPermissions: {},
      parsedRoutes: {},
      parsedIcons: {},
      navItems: []
    };
  },
  created() {
    this.parsedPermissions = JSON.parse(this.permissions);
    this.parsedRoutes = JSON.parse(this.routes);
    this.parsedIcons = JSON.parse(this.icons);
    this.buildNavItems();
  },
  methods: {
    getNavClass(routeName) {
      return routeName === this.$route ? 'nav-link active' : 'nav-link';
    },
    canView(permission) {
      return this.parsedPermissions[permission] || false;
    },
    buildNavItems() {
      const keys = Object.keys(this.parsedRoutes);
      keys.forEach(key => {
        if (this.parsedRoutes[key] && this.canView(key)) {
          this.navItems.push({
            name: key,
            route: this.parsedRoutes[key],
            icon: this.parsedIcons[key]
          });
        }
      });
    },
    showAdminDropdown() {
      //does nothing
    }
  }
};
</script>