<template>
	<fieldset>
		<div class="inputwrapper full-width">
			<label>Mounting Position(s)</label>
			<!-- Position list varies by Part Terminology -->
			<div class="d-flex flex-row">
				<div v-for="position in positions" :key="position.PositionID" id="part_position_group" class="mx-2">
					<input type="checkbox" name="part_position" :id="position.PositionID"
						:value="position.PositionID" v-model="selectedPositions"/>
					<label :for="position.PositionID">{{ position.Position }}</label>
				</div>
			</div>
		</div>
	</fieldset>
</template>

<script>
import axios from 'axios';

export default {
	props: {
		positions: {
			type: Array
		},
		previewPart: {
			type: [Object, Array],
			default: null,
		}
	},
	data() {
		return {
			description: '',
			parent_company_id: '',
			brand_owner_id: '',
			brand_id: '',
			product_group_id: '',
			product_subgroup_id: '',
			pcdb_part_terminology_id: '',
			title: '',
			product_identifier: '',
			variant_code: '',
			development_phase: '',
			selectedPositions: [],
			lemnocity_position: null,
		}
	},
	watch: {
		previewPart: {
			immediate: true,
			deep: true,
			handler(newVal) {
				this.parent_company_id = newVal[0]['parent_company_id'];
				this.brand_owner_id = newVal[0]['brand_owner_id'];
				this.brand_id = newVal[0]['brand_id'];
				this.product_group_id = newVal[0]['product_group_id'];
				this.product_subgroup_id = newVal[0]['product_subgroup_id'];
				this.pcdb_part_terminology_id = newVal[0]['pcdb_part_terminology_id'];
				this.title = newVal[0]['title'];
				this.product_identifier = newVal[0]['product_identifier'];
				this.variant_code = null;
				this.development_phase = newVal[0]['development_stage_id'];
				if (newVal[0]['description'] != null) {
					this.description = newVal[0]['description'];
				}
				if (newVal[0]['position_id'] != null) {
					this.lemnocity_position = newVal[0]['position_id'];
					this.selectedPositions = [this.lemnocity_position];
				}
			}
		},
		selectedPositions: {
			handler(newVal) {
				this.startForgeProcess();
			},
			deep: true
		}
	},
	methods: {
		async startForgeProcess() {
			var partData = {
				parent_company_id: this.parent_company_id,
				brand_owner_id: this.brand_owner_id,
				brand_id: this.brand_id,
				product_group_id: this.product_group_id,
				product_subgroup_id: this.product_subgroup_id,
				pcdb_part_terminology_id: this.pcdb_part_terminology_id,
				title: this.title,
				product_identifier: this.product_identifier,
				variant_code: this.variant_code,
				development_phase: this.development_phase,
				description: this.description,
				position: this.selectedPositions
			}
			try {
				const response = await axios.post('/tenant/part-forge/update', partData);
				if (response.data.success) {
					this.$emit('success', "Position Updated");
				}
			} catch (error) {
				this.$emit('error', error);
			}
		}
	}
}
</script>
