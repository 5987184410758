<template>
    <div>
        <div v-if="errorMessage" class="alert alert-danger"><i class="fa fa-triangle-exclamation mx-2"></i>{{ errorMessage
        }}</div>
        <div v-if="successMessage" class="alert alert-success"><i class="fa fa-circle-check mx-2"></i>{{ successMessage }}
        </div>
        <div class="d-flex flex-row">
            <div class="d-flex flex-column">
                <h1>Measured</h1>
                <div class="d-flex flex-row flex-wrap">
                    <div v-for="priorites in uomPriorities" :key="priorites.id" class="card m-2 measuredPriorites">
                        <div class="card-body d-flex flex-column">
                            <h3 class="card-title">{{ priorites[0].autocare_p_a_d_b_part_attributes.PAName }}</h3>
                            <h5 class="card-title">{{ priorites[0].autocare_p_a_d_b_part_attributes.PADescr }}</h5>
                            <prioritie :uom-information="priorites" @message="showChildMessage" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-50 mt-2">
                <h1>Non-Measured</h1>
                <div>
                    <nonMeasured :uom-priorities="nonMeasured" />
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.card-title {
    word-wrap: break-word;
}
</style>

<script>
import UOMPrioritieComponent from "./UOMPriorities/UOMPrioritieComponent.vue";
import UOMNonMeausreComponent from "./UOMPriorities/UOMNonMeasuredComponent.vue"

export default {
    props: {
        uomPriorities: Object,
        nonMeasured: Object
    },
    components: {
        'prioritie': UOMPrioritieComponent,
        'nonMeasured': UOMNonMeausreComponent,
    },
    data() {
        return {
            errorMessage: "",
            successMessage: "",
        }
    },
    methods: {
        showAlert(message, type) {
            this[type] = message;
            setTimeout(() => {
                this[type] = '';
            }, 2000);
        },
        showChildMessage(message) {
            if (message[0] == 'successMessage') {
                this.showAlert(message[1], message[0]);
            }
            if (message[0] == 'Error' | message[0] == 'errorMessage') {
                this.showAlert(message[1], message[0]);
            }
        }
    }
}
</script>