<template>
  <fieldset>
    <div class="inputrow">
      <Label>Project Management</Label>
      <div class="inputrow mx-2 mt-2">
        <div class="inputwrapper full-width">
          <label for="part__development_stage">Development Phase</label>
          <div id="part_development_phase_group">
            <input type="radio" v-model="developmentPhase" name="part_development_phase_id"
              id="part_development_phase_id__201" value="201" @change="autoSave" />
            <label for="part_development_phase_id__201">Prototype</label>
            <input type="radio" v-model="developmentPhase" name="part_development_phase_id"
              id="part_development_phase_id__202" value="202" @change="autoSave" />
            <label for="part_development_phase_id__202">Fitment</label>
            <input type="radio" v-model="developmentPhase" name="part_development_phase_id"
              id="part_development_phase_id__203" value="203" @change="autoSave" />
            <label for="part_development_phase_id__203">Testing</label>
            <input type="radio" v-model="developmentPhase" name="part_development_phase_id"
              id="part_development_phase_id__204" value="204" @change="autoSave" />
            <label for="part_development_phase_id__204">Final</label>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    parts: {
      type: Array,
      required: true,
    },
    productgroups: {
      type: [Array, Object],
      default: null,
    },
    productsubgroup: {
      type: [Array, Object],
      default: null,
    },
    activescope: {
      type: [Array, Object],
      default: null,
    },
    saas: {
      type: [Array, Object]
    },
    previewPart: {
      type: [Object, Array],
      default: null,
    }
  },
  data() {
    return {
      description: '',
      parent_company_id: '',
      brand_owner_id: '',
      brand_id: '',
      product_group_id: '',
      product_subgroup_id: '',
      pcdb_part_terminology_id: '',
      title: '',
      product_identifier: '',
      variant_code: '',
      selectedPositions: [],
      lemnocity_position: null,
      developmentPhase: '',  // This will store the selected development phase
    };
  },
  computed: {
    filteredProductSubgroups() {
      if (!this.selectedProductGroup) return [];
      return this.productSubgroups.filter(subgroup => subgroup.product_group_id === this.selectedProductGroup);
    },
  },
  watch: {
    parts: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.part = newVal;
      }
    },
    productgroups: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal == null) {
          this.isNew = true;
        } else {
          this.selectedProductGroup = newVal.title;
        }
      },
    },
    productsubgroup: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal != null && newVal.length == 1) {
          this.selectedProductSubgroup = newVal[0].title;
        }
      },
    },
    activescope: {
      immediate: true,
      deep: true,
      handler(newVal) {

      },
    },
    saas: {
      immediate: true,
      deep: true,
      handler(newVal) {
        //console.log(newVal);
      },
    },
    previewPart: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.parent_company_id = newVal[0]['parent_company_id'];
          this.brand_owner_id = newVal[0]['brand_owner_id'];
          this.brand_id = newVal[0]['brand_id'];
          this.product_group_id = newVal[0]['product_group_id'];
          this.product_subgroup_id = newVal[0]['product_subgroup_id'];
          this.pcdb_part_terminology_id = newVal[0]['pcdb_part_terminology_id'];
          this.title = newVal[0]['title'];
          this.product_identifier = newVal[0]['product_identifier'];
          this.variant_code = null;
          this.description = newVal[0]['description'] != null ? newVal[0]['description'] : '';
          this.lemnocity_position = newVal[0]['position_id'] != null ? newVal[0]['position_id'] : null;
          this.developmentPhase = newVal[0]['development_phase_id']; // Ensure this is set correctly
        }
      },
    }
  },
  methods: {
    async startForgeProcess() {
      var partData = {
        parent_company_id: this.parent_company_id,
        brand_owner_id: this.brand_owner_id,
        brand_id: this.brand_id,
        product_group_id: this.product_group_id,
        product_subgroup_id: this.product_subgroup_id,
        pcdb_part_terminology_id: this.pcdb_part_terminology_id,
        title: this.title,
        product_identifier: this.product_identifier,
        variant_code: this.variant_code,
        development_phase: this.developmentPhase, // Ensure this is the correct property
        description: this.description,
        position: this.lemnocity_position
      };

      try {
        const response = await axios.post('/tenant/part-forge/update', partData);
        if (response.data.success) {
          this.$emit('success', "Project management Updated");
        }
      } catch (error) {
        this.$emit('error', error);
      }
    },
    autoSave() {
      this.startForgeProcess();
    },
  },
  mounted() {
    // Ensure the correct radio button is checked when the component is mounted
    if (this.previewPart && this.previewPart.length > 0) {
      this.developmentPhase = this.previewPart[0]['development_phase_id'];
    }
  }
};
</script>
