<template>
  <div id="mainheader">
    <a href="/tenants" id="header_title">
      <img src="/images/lemnocity_header.png" /><span>Limnocity</span>
    </a>
    <div id="headerComponent" class="d-flex justify-content-end align-items-center">
      <div class="navlinks d-flex flex-row">
        <div class="initials-circle user-circle" onclick="UserDropdown()">
          <h4>{{ UserInitial }}</h4>
          <div class="dropdown-menu" id="userdown-menu">
            <div class="d-flex justify-content-center user-item">
              <i class="fa fa-user"></i>
              <h6 class="user-item" id="userdropdown">{{ UserName }}</h6>
            </div>
            <div class="dropdown-divider"></div>
            <!-- User Dropdown Menu Items -->
            <div @click.stop>
              <a class="dropdown-item" href="#">
                <div class="d-flex" @click="toggleNestedTenantDropdown">
                  <i class="fa fa-caret-left arrow-right" @click="toggleNestedTenantDropdown"></i>
                  <span class="mx-1">Tenants</span>
                </div>
                <div v-if="showNestedTenantDropdown" class="dropdown-menu nested-dropdown">
                  <div v-for="tenant in AllTenants" :key="tenant.id" class="dropdown-item"
                    @click="selectTenant(tenant['t.id'])">
                    {{ tenant['t.tenant_name'] }}
                  </div>
                </div>
              </a>
            </div>
            <a class="dropdown-item" href="#">Settings</a>
            <a class="dropdown-item" href="#">Information</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="/logout">Logout</a>
          </div>
        </div>
        <div class="initials-circle tenant-circle" onclick="TenantDropdown()">
          <h4>{{ TenantInitial }}</h4>
          <div class="dropdown-menu" id="tenantdown-menu">
            <div class="d-flex justify-content-center user-item">
              <i class="fa-solid fa-building-user"></i>
              <h6 class="user-item" id="tenantdropdown">{{ TenantName }}</h6>
            </div>
            <div class="dropdown-divider"></div>
            <div v-if="isCentralDomain" class="nav-dropdown">
              <div @click.stop>
                <a class="dropdown-item" href="#">
                  <div class="d-flex" @click="toggleAdminDropdown">
                    <i class="fa fa-caret-left arrow-right mt-1" @click="toggleAdminDropdown"></i>
                    <img class="drop-limnocity mx-1" src="/images/lemnocity_header.png" alt="Limnocity Icon"><span
                      class="mt-1">Central</span>
                  </div>
                  <div v-if="showAdminDropdown" class="dropdown-menu nested-dropdown">
                    <a href="/databrowser" class="dropdown-item"><i class="fa fa-database ml-3"></i> Data Browser</a>
                    <a href="/uom-priorites" class="dropdown-item"><i class="fa fa-ruler-combined ml-3"></i> UOM
                      Priorities</a>
                    <a href="/fileupload" class="dropdown-item"><i class="fa fa-solid fa-upload"></i> Fileupload</a>
                    <a href="/part-catalog" class="dropdown-item"><i class="fa fa-solid fa-book-open"></i> Part Catalog</a>
                    <a href="/partnumberschemes" class="dropdown-item"><i class="fa fa-solid fa-hashtag"></i> Part Number Schemes</a>
                    <a href="/users" class="dropdown-item"><i class="fa fa-users"></i> Users</a>
                    <a href="/roles" class="dropdown-item"><i class="fa fa-user-shield"></i> Roles</a>
                  </div>
                </a>
              </div>
            </div>
            <SideNavBar class="dropdown-sidenav" :tenant-dashboard="null" :tenant-name="null" :leads-url="null"
              :permissions="permissions" :routes="routes" :icons="icons" />
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" @click="exitTenant">Exit Tenant</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNavBar from "./SideNavBar.vue";

export default {
  props: {
    user: {
      type: String,
      required: true
    },
    tenant: {
      type: String,
      required: true
    },
    saas: {
      type: String,
      required: true
    },
    permissions: String,
    routes: String,
    icons: String,
  },
  components: {
    SideNavBar
  },
  data() {
    return {
      UserInitial: null,
      UserName: null,
      TenantInitial: null,
      TenantName: null,
      AllTenants: [], // Holds tenant id and tenant name
      CUser: {},
      CTenant: {},
      CSAAS: {},
      TenantSelected: false,
      showUserDropdown: false,
      showNestedTenantDropdown: false,
      showTenantDropdown: false,
      showAdminDropdown: false,
      isCentralDomain: false,
      headerData: {
        tenantDashboard: '',
        tenantName: '',
        leadsUrl: '',
        permissions: {},
        routes: {},
        icons: {},
      }
    };
  },
  created() {
    this.CUser = JSON.parse(this.user);
    this.CTenant = JSON.parse(this.tenant);
    if (this.saas != '') {
      this.CSAAS = JSON.parse(this.saas);
    }
    this.updateUserDetails(this.CUser);
    this.updateTenantDetails(this.CTenant);
    this.checkTenantSelection(this.CSAAS);
    this.isCentralDomain = this.CSAAS.is_central_domain;
  },
  methods: {
    updateUserDetails(user) {
      this.UserInitial = user.name ? `${user.name.split(' ')[0][0]}${user.name.split(' ')[1][0]}` : 'NN';
      this.UserName = user.name || 'Unknown';
    },
    updateTenantDetails(tenant) {
      this.TenantInitial = tenant[0]?.t?.tenant_name[0] || '';
      this.TenantName = tenant[0]?.t?.tenant_name || 'Unknown';
      // Check if tenant is an array and update AllTenants
      if (Array.isArray(tenant)) {
        this.AllTenants = tenant;
      } else {
        this.AllTenants = [];
      }
    },
    checkTenantSelection(saas) {
      if (!saas) {
        //console.log("Tenant has not been selected.");
      } else {
        const activeTenantId = saas['active_tenant_id'];
        this.AllTenants.forEach(tenant => {
          if (tenant['t.id'] === activeTenantId) {
            this.TenantName = tenant['t.tenant_name'];
            this.TenantInitial = tenant['t.tenant_name'].substring(0, 1);
          }
        });
        this.TenantSelected = true;
      }
    },
    toggleUserDropdown() {
      this.showUserDropdown = !this.showUserDropdown;
      this.showNestedTenantDropdown = false; // Close nested tenant dropdown if open
      this.showTenantDropdown = false;
      this.showAdminDropdown = false; // Close admin dropdown if open
    },
    toggleNestedTenantDropdown() {
      this.showNestedTenantDropdown = !this.showNestedTenantDropdown;
    },
    toggleTenantDropdown() {
      this.showUserDropdown = false;
      this.showNestedTenantDropdown = false;
      this.showTenantDropdown = !this.showTenantDropdown;
      this.showAdminDropdown = false; // Close admin dropdown if open
    },
    toggleAdminDropdown() {
      this.showAdminDropdown = !this.showAdminDropdown;
    },
    selectTenant(tenantId) {
      this.submitTenantForm(tenantId);
    },
    exitTenant() {
      this.submitTenantForm(0); // Use 0 to signify exiting the tenant
    },
    submitTenantForm(tenantId) {
      const form = document.createElement('form');
      form.action = '/set/tenant'; // Adjust this to match your route
      form.method = 'POST';

      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'selected_tenant';
      input.value = tenantId;

      const csrfInput = document.createElement('input');
      csrfInput.type = 'hidden';
      csrfInput.name = '_token'; // Adjust this to match your CSRF token name
      csrfInput.value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      form.appendChild(input);
      form.appendChild(csrfInput);
      document.body.appendChild(form);
      form.submit();
    }
  },
  watch: {
    CUser: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.updateUserDetails(newVal);
      }
    },
    CTenant: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.updateTenantDetails(newVal);
      }
    },
    CSAAS: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.checkTenantSelection(newVal);
      }
    }
  }
};
</script>

<style scoped>
#mainheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #5c110f;
  /* Adjust to match your color palette */
}

#header_title {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffd700;
  /* Adjust to match your color palette */
}

#header_title img {
  height: 40px;
  margin-right: 10px;
}

.initials-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  font-weight: bold;
  color: #ffd700;
  /* Text color */
  background-color: #9b2c2c;
  /* Default background color */
  border: 2px solid #ffd700;
  /* Border to match the color palette */
}

.user-circle {
  background-color: #9b2c2c;
  /* Adjust to match user color in your palette */
}

.tenant-circle {
  background-color: #d69e2e;
  /* Adjust to match tenant color in your palette */
}

.initials-circle h4 {
  margin: 0;
  font-size: 1rem;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background: #7b1d1d;
  /* Lighter dropdown background color */
  border: 1px solid #ffd700;
  border-radius: 5px;
  width: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  padding: 10px;
  text-decoration: none;
  color: #ffd700;
  /* Dropdown item text color */
  display: block;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #9b2c2c;
  /* Hover color to match theme */
}

.dropdown-divider {
  height: 1px;
  background-color: #ffd700;
  /* Divider color */
  margin: 5px 0;
}

.nested-dropdown {
  position: absolute;
  top: 35px;
  right: 100%;
  /* Adjusted to make the dropdown pop out on the left */
  background: #7b1d1d;
  /* Lighter dropdown background color */
  border: 1px solid #ffd700;
  border-radius: 5px;
  width: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.fa-caret-left {
  float: right;
}

.user-item {
  color: #ffd700;
}

.arrow-right {
  float: right;
}

</style>
