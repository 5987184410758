<template>
  <div class="container">
    <div v-if="errorMessage" class="alert alert-danger">
      <i class="fa fa-triangle-exclamation mx-2"></i>{{ errorMessage }}
    </div>
    <div v-if="successMessage" class="alert alert-success">
      <i class="fa fa-circle-check mx-2"></i>{{ successMessage }}
    </div>
    <div class="card">
      <div class="card-header d-flex">
        File Upload <p :hidden="!file" class="mx-2"> | {{ file ? file.name : '' }} </p>
      </div>
      <div>
        <div class="d-flex m-2">
          <div class="dropzone" @dragover.prevent @drop="handleDrop">
            <p>Drag & drop files here</p>
            <i class="fa-solid fa-file-arrow-up"></i>
          </div>
          <div class="card-body">
            <div v-if="parentCompany == null">
              <div class="d-flex my-1">
                <label class="align-self-lg-center">*Parent Company:</label>
                <div v-if="parentCompanies && parentCompanies.length > 0" class="d-flex">
                  <select v-model="parentCompany" id="parent_company_id" name="parent_company_id" class="xxlong">
                    <option v-for="company in parentCompanies" :key="company.UUID" :value="company.UUID">
                      {{ company.parent_company_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="d-flex my-1">
                <label class="align-self-lg-center">*Brand:</label>
                <div v-if="userBrand && userBrand.length > 0" class="d-flex">
                  <select v-model="brand" id="brand_id" name="brand_id" class="xxlong">
                    <option v-for="brand in userBrand" :key="brand.UUID" :value="brand.UUID">
                      {{ brand.brand_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="d-flex my-1">
                <div v-if="userSubbrands && userSubbrands.length > 0" class="d-flex">
                  <label class="align-self-lg-center">Subbrand:</label>
                  <select v-model="subbrand" id="subbrand_id" name="subbrand_id" class="xxlong">
                    <option v-for="subbrand in userSubbrands" :key="subbrand.UUID" :value="subbrand.UUID">
                      {{ subbrand.subbrand_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="d-flex my-1">
                <label class="align-self-lg-center">Version Sequence:</label>
                <input type="text" v-model="versionSequence" />
              </div>
              <div class="d-flex my-1">
                <label class="align-self-lg-center">Downloadable:</label>
                <input type="checkbox" v-model="downloadable" />
              </div>
            </div>
            <div v-else>
              <div class="d-flex my-1">
                <label class="align-self-lg-center">*Parent Company ID:</label>
                <input type="text" v-model="parentCompany" />
              </div>
              <div class="d-flex my-1">
                <label class="align-self-lg-center">*Brand ID:</label>
                <input type="text" v-model="brand" />
              </div>
              <div class="d-flex my-1">
                <label class="align-self-lg-center">Version Sequence:</label>
                <input type="text" v-model="versionSequence" />
              </div>
              <div class="d-flex my-1">
                <label class="align-self-lg-center">Downloadable:</label>
                <input type="checkbox" v-model="downloadable" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer d-flex">
          <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload">
          <button @click="chooseFile">Choose File</button>
          <button @click="uploadFile" :disabled="!file">Upload</button>
        </div>
      </div>
    </div>
    <div v-if="files && files.length > 0" class="my-3">
      <table>
        <thead>
          <tr>
            <th>Original Filename</th>
            <th>MIME Major</th>
            <th>MIME Minor</th>
            <th>Version</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in files" :key="file.id">
            <td>{{ file.original_filename }}</td>
            <td>{{ file.mime_major }}</td>
            <td>{{ file.mime_minor }}</td>
            <td>{{ file.version_sequence }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tenant: {
      type: String,
    },
    userBrand: {
      type: Array,
    },
    userSubbrands: {
      type: Array,
    },
    parentCompanies: {
      type: Array,
    },
    userFiles: {
      type: Array,
    },
    previewPart: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      file: null,
      isDragging: false,
      parentCompany: null,
      brand: null,
      subbrand: null,
      fileTypeId: null,
      downloadable: false,
      versionSequence: null,
      files: [],
      partForgeFile: false,
      forgeKey: null,
    };
  },
  watch: {
    previewPart: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal != null) {
          this.forgeKey = newVal[0].forge_key;
          this.parentCompany = newVal[0].parent_company_id;
          this.brand = newVal[0].brand_id;
          this.partForgeFile = true;
        }
      },
    },
  },
  methods: {
    chooseFile() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    handleDrop(event) {
      event.preventDefault();
      this.file = event.dataTransfer.files[0];
    },
    async uploadFile() {
      if (!this.file) {
        alert('No file selected!');
        return;
      }
      if (!this.parentCompany || !this.brand) {
        alert('No parent company and/or brand selected!');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('from', this.tenant);
      formData.append('parent', this.parentCompany);
      formData.append('brand', this.brand);
      formData.append('subbrand', this.subbrand);
      formData.append('versionSequence', this.versionSequence);
      formData.append('downloadable', this.downloadable);
      formData.append('forgeKey', this.forgeKey);

      if (this.partForgeFile) {
        try {
          await axios.post('/part-forge/fileupload', formData)
            .then(response => {
              this.files = response.data.files;
              this.showAlert('File upload successful.', 'successMessage');
            })
            .catch(error => {
              this.showAlert('Error: ' + error, 'errorMessage');
            });
        } catch (error) {
          console.error('Error uploading file:', error);
          this.showAlert('Error: ' + error, 'errorMessage');
        }
      }
      else {
        try {
          await axios.post('/upload', formData)
            .then(response => {
              this.files = response.data.files;
              this.showAlert('File upload successful.', 'successMessage');
            })
            .catch(error => {
              this.showAlert('Error: ' + error, 'errorMessage');
            });
        } catch (error) {
          console.error('Error uploading file:', error);
          this.showAlert('Error: ' + error, 'errorMessage');
        }
      }
    },
    showAlert(message, type) {
      this[type] = message;
    },
  },
  mounted() {
    this.files = this.userFiles;
  }
};
</script>

<style scoped>
.dropzone {
  border: 2px dashed #ccc;
  padding: 10%;
  text-align: center;
  background-color: white;
}

.dropzone.drag-over {
  background-color: #e0e0e0;
}
</style>