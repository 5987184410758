<template>
    <div>
        <div v-if="errorMessage" class="alert alert-danger"><i class="fa fa-triangle-exclamation mx-2"></i>{{ errorMessage
        }}</div>
        <div v-if="successMessage" class="alert alert-success"><i class="fa fa-circle-check mx-2"></i>{{ successMessage }}
        </div>
        <div class="d-flex flex-wrap" style="height: 50%;">
            <form @submit.prevent="sendDataToServer" class="card m-2" style="width: 18rem; position: relative;">
                <div class="card-body d-flex flex-column">
                    <div class="d-flex flex-column card-title mb-4">
                        <input v-model="formData.title" class="mt-2 mb-4" type="title" placeholder="Group title">
                        <textarea v-model="formData.description" placeholder="Group description" id="textarea"
                            name="comments" maxlength="255"></textarea>
                    </div>
                </div>
                <div class="card-footer">
                    <button class="btn btn-outline-light">
                        <i class="fa fa-floppy-disk"></i> Save Group
                    </button>
                </div>
            </form>
            <div v-for="group in localPartAttributeGroup" :key="group.name" class="card m-2 dragItem">
                <div>
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">{{ group.title }}</h5>
                        <p class="card-text" id="attribute_description">{{ group.description }}</p>
                        <div class="card">
                            <members :group="group" :can-drag="canDrag"></members>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import PartAttributesMembers from './PartAttributesMembers.vue';

export default {
    props: {
        partAttributeGroup: Array,
        canDrag: Boolean,
    },
    data() {
        return {
            formData: {
                title: "",
                description: "",
            },
            localPartAttributeGroup: [],
            partID: null,
            errorMessage: "",
            successMessage: "",
        };
    },
    components: {
        'members': PartAttributesMembers,
    },
    methods: { // Use 'methods' instead of 'method'
        sendDataToServer() {
            axios.post(`/part-attributes/add/${this.partID}`, this.formData)
                .then(response => {
                    if (response.data.error) {
                        this.showAlert(response.data.error, 'errorMessage');
                    } else {
                        this.showAlert('Group Added.', 'successMessage');
                    }
                })
                .catch(error => {
                    this.showAlert('Error: ' + error, 'errorMessage');
                });
        },
        showAlert(message, type) {
            this[type] = message;
            setTimeout(() => {
                this[type] = '';
            }, 3000); 
            if([type] == 'successMessage'){
                window.location.reload();
            }
        },
    },
    watch: {
        partAttributeGroup: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.partID = newVal[0]['pcdb_part_terminology_id'];
                this.localPartAttributeGroup = JSON.parse(JSON.stringify(newVal));
            }
        },
    },
};
</script>