var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._v(" "),_c('ul',{staticClass:"nav nav-pills nav-side flex-column"},_vm._l((_vm.navItems),function(item){return _c('li',{key:item.name,staticClass:"nav-item"},[_c('a',{class:_vm.getNavClass(item.route),attrs:{"href":item.route}},[_c('i',{class:item.icon}),_vm._v(" "+_vm._s(item.name)+"\n      ")])])}),0),_vm._v(" "),_c('ul',{staticClass:"nav nav-pills nav-side flex-column"},[_c('li',{staticClass:"side-limnocity-nav-item"},[_c('a',{attrs:{"href":"/partforge"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"#ccb17a","version":"1.1","id":"Layer_1","width":"20px","height":"20px","viewBox":"0 0 256 256","enable-background":"new 0 0 256 256","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M248,91.3V67H80v8H9c0,0,10.7,40.6,67.3,40.6c30.3,0,34.4,12.7,34.4,19.1c0,8.4-5.1,21.9-36.7,32.8V191h38.7\n                        c6.8-5.2,15.3-8.2,24.5-8.2s17.7,3.1,24.5,8.2H201c0,0,0-15.1,0-22.9c-23.4-7.7-38.7-20.4-38.7-34.8\n                        C162.3,110.6,200.1,92.5,248,91.3z M80,87c-52,0-52-4-52-4h52C80,83,80,85.4,80,87z M88,79v-4h152v4H88z"}})]),_vm._v(" Part Forge\n      ")]),_vm._v(" "),_vm._m(1),_vm._v(" "),(_vm.showAdminDropdown)?_c('div',{staticClass:"dropdown-menu nested-dropdown"},[_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-pills nav-side flex-column"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/dashboard"}},[_c('i',{staticClass:"fa fa-dashboard"}),_vm._v(" Dashboard "),_c('i',{staticClass:"fa fa-gear"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/product-groups"}},[_c('i',{staticClass:"fa fa-solid fa-layer-group mx-1"}),_vm._v(" Product\n        Groups")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/part-attributes"}},[_c('i',{staticClass:"fa fa-car-battery mx-1 my-1"}),_vm._v(" Part\n          Attributes")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"/databrowser"}},[_c('i',{staticClass:"fa fa-database"}),_vm._v(" Data Browser")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"/uom-priorites"}},[_c('i',{staticClass:"fa fa-ruler-combined"}),_vm._v(" UOM\n          Priorities")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"/fileupload"}},[_c('i',{staticClass:"fa fa-solid fa-upload"}),_vm._v(" Fileupload")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"/users"}},[_c('i',{staticClass:"fa fa-users"}),_vm._v(" Users")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"/roles"}},[_c('i',{staticClass:"fa fa-user-shield"}),_vm._v(" Roles")])
}]

export { render, staticRenderFns }