<template>
    <fieldset>
      <div class="inputrow">
        <Label>Kit Components</Label>
      </div>
    </fieldset>
</template>
  
  <script>
  export default {

  };
  </script>