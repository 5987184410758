<template>
  <div>
    <div class="inputrow my-2">
      <div v-if="isNew" class="row">
        <h3>Adding new part requires a product group. The subgroup is optional.</h3>
        <div class="col-md-4">
          <div class="card p-2 m-3">
            <div class="col">
              <div class="col-md-11">
                <div class="form-group">
                  <label for="productGroups">Select an existing one.</label>
                  <button id="createProductGroupBtn" @click="createProductGroup"><i class="fa fa-plus m-2"></i></button>
                  <label for="productGroups">*Product Groups</label>
                  <select v-model="selectedProductGroup" class="xxlong" id="productGroups"
                    @change="onProductGroupChange">
                    <option value="">Select a Product Group</option>
                    <option v-for="group in productGroups" :key="group.id" :value="group.id">
                      ID: {{ group.id }} - Group Name: {{ group.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="productSubgroups">Product Subgroups</label>
                  <select v-model="selectedProductSubgroup" class="xxlong" id="productSubgroups">
                    <option value="">Select a Product Subgroup</option>
                    <option v-for="subgroup in productSubgroups" :key="subgroup.id" :value="subgroup.id">
                      ID: {{ subgroup.id }} - Subgroup Name: {{ subgroup.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <label for="part__title">Title</label>
                <div>
                  <input v-model="title" type="text" name="part__title" id="part__title" class="xxlong" @blur="autoSave"
                    @keyup.enter="autoSave">
                </div>
                <label for="part__identifier">Product Identifier</label>
                <div>
                  <input v-model="productIdentifier" type="text" name="part__identifier" id="part__identifier"
                    class="xxlong" @blur="autoSave" @keyup.enter="autoSave">
                </div>
                <label for="part__variant_code">Variant Code</label>
                <div>
                  <input v-model="variantCode" type="text" name="part__variant_code" id="part__variant_code"
                    class="xxlong" @blur="autoSave" @keyup.enter="autoSave">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card m-3">
            <div class="card-header">Loaded Part Data</div>
            <div class="card-body">
              <label>Part Name</label>
              <p>{{ part[0].PartTerminologyName }}</p>
              <label>Part ID</label>
              <p>{{ part[0].PartTerminologyID }}</p>
              <label>Part Description</label>
              <p>{{ part[0].part_description.PartsDescription }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-md-3">
            <label for="productGroups">Product Groups</label>
            <div>
              <input v-model="selectedProductGroup" type="text" name="part__title" id="part__title" class="xxlong"
                @blur="autoSave" @keyup.enter="autoSave">
            </div>
            <div v-if="selectedProductSubgroup">
              <label for="productSubgroups">Product Subgroups</label>
              <div>
                <input v-model="selectedProductSubgroup" type="text" name="part__title" id="part__title" class="xxlong"
                  @blur="autoSave" @keyup.enter="autoSave">
              </div>
            </div>
            <div v-else>
              <div class="form-group">
                <label for="productSubgroups">Product Subgroups</label>
                <select v-model="selectedProductSubgroup" class="xxlong" id="productSubgroups" @change="autoSave">
                  <option value="">Select a Product Subgroup</option>
                  <option v-for="subgroup in productSubgroups" :key="subgroup.id" :value="subgroup.id">
                    ID: {{ subgroup.id }} - Subgroup Name: {{ subgroup.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label for="part__identifier">Product Identifier</label>
            <div>
              <input v-model="productIdentifier" type="text" name="part__identifier" id="part__identifier"
                class="xxlong" @blur="autoSave" @keyup.enter="autoSave">
            </div>
            <label for="part__variant_code">Variant Code</label>
            <div>
              <input v-model="variantCode" type="text" name="part__variant_code" id="part__variant_code" class="xxlong"
                @blur="autoSave" @keyup.enter="autoSave">
            </div>
          </div>
          <div class="col-md-3">
            <label for="productGroups">Product Title</label>
            <div>
              <input v-model="title" type="text" name="part__title" id="part__title" class="xxlong" @blur="autoSave"
                @keyup.enter="autoSave">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash.debounce';

export default {
  props: {
    parts: {
      type: Array,
      required: true,
    },
    productgroups: {
      type: [Array, Object],
      default: null,
    },
    productsubgroup: {
      type: [Array, Object],
      default: null,
    },
    activescope: {
      type: [Array, Object],
      default: null,
    },
    saas: {
      type: [Array, Object]
    },
    previewPart: {
      type: [Object, Array],
      default: null,
    }
  },
  data() {
    return {
      isNew: false,
      productGroups: [],
      productSubgroups: [],
      selectedProductGroup: null,
      selectedProductSubgroup: null,
      part: [],
      title: null,
      productIdentifier: null,
      variantCode: null,
      developmentPhase: null,
      lemnocity_position: null,
      developmentPhase: null,
    };
  },
  computed: {
    filteredProductSubgroups() {
      if (!this.selectedProductGroup) return [];
      return this.productSubgroups.filter(subgroup => subgroup.product_group_id === this.selectedProductGroup);
    },
  },
  watch: {
    parts: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.part = newVal;
      }
    },
    productgroups: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal == null) {
          this.isNew = true;
        } else {
          this.selectedProductGroup = newVal.title;
        }
      },
    },
    productsubgroup: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal != null && newVal.length == 1) {
          this.selectedProductSubgroup = newVal[0].title;
        } else {
          this.fetchProductSubgroups();
        }
      },
    },
    activescope: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal && newVal.brand_id) {
          this.fetchProductGroups();
        }
        if (newVal.brand_id == null && this.isNew) {
          this.fetchProductGroups();
        }
      },
    },
    saas: {
      immediate: true,
      deep: true,
      handler(newVal) {
        //console.log(newVal);
      },
    },
    previewPart: {
      immediate: true,
      deep: true,
      handler(newVal) {
        console.log(newVal);
        if (newVal != null) {
          if (newVal[0].product_group_id != null) {
            this.selectedProductGroup = newVal[0].product_group_id;
          }
          if (newVal[0].product_subgroup_id != null) {
            this.selectedProductSubgroup = newVal[0].product_subgroup_id;
          }
          if (newVal[0].product_identifier != null) {
            this.productIdentifier = newVal[0].product_identifier;
          }
          if (newVal[0].development_phase_id != null) {
            this.developmentPhase = newVal[0].development_phase_id;
          }
          if (newVal[0].title != null) {
            this.title = newVal[0].title;
          }
          if (newVal[0]['position_id'] != null) {
					this.lemnocity_position = newVal[0]['position_id'];
				  }
          if (newVal[0]['development_phase_id'] != null) {
					this.developmentPhase = newVal[0]['development_phase_id'];
				  }
        }
      },
    }
  },
  methods: {
    showAlert(message, type) {
      this[type] = message;
    },
    async fetchProductGroups() {
      try {
        const response = await axios.get(`/tenant/product-groups/all?id=${this.activescope.brand_id}`);
        this.productGroups = response.data.data.product_groups;
        this.productSubgroups = response.data.data.product_subgroups;
      } catch (error) {
        console.error('Error fetching product groups:', error);
      }
    },
    onProductGroupChange() {
      this.startForgeProcess();
    },
    onProductSubgroupChange() {
      this.startForgeProcess();
    },
    createProductGroup() {
      const route = `/tenant/brands/product-groups/${this.activescope.brand_id}`;
      window.location.href = route;
    },
    async startForgeProcess() {
      if (this.selectedProductGroup != null) {
        var partData = {
          parent_company_id: this.activescope.parent_company_id,
          brand_owner_id: this.activescope.brand_owner_id,
          brand_id: this.activescope.brand_id,
          product_group_id: this.selectedProductGroup,
          product_subgroup_id: this.selectedProductSubgroup,
          pcdb_part_terminology_id: this.part[0].PartTerminologyID,
          title: this.title,
          product_identifier: this.productIdentifier,
          variant_code: this.variantCode,
          development_phase: this.developmentPhase,
          position: this.lemnocity_position,
          development_phase: this.developmentPhase,
        };

        try {

          const response = await axios.post('/tenant/part-forge/update', partData);
          if (response.data.success) {
            const { ProductGroup, ProductSubgroup } = response.data.data;
            const parts = response.data.data.parts[0].PartTerminologyID;
            const forge_id = response.data.data.forge_id;

            // Redirect to the new route with query parameters
            window.location.href = `/forge_direct?parts=${parts}&productGroup=${ProductGroup}&productSubgroup=${ProductSubgroup}&forge_session=${forge_id}`;
          }
        } catch (error) {
          this.$emit('error', error);
        }
      } else {
        var error = 'Product Group can not be null.';
        this.$emit('error', error);
      }
    },
    autoSave() {
      this.startForgeProcess();
    },
    fetchProductSubgroups() {
      axios
        .get('/product-group/product-subgroup')
        .then((response) => {
          this.productSubgroups = response.data;
          //this.$emit('success', "Product subgroup updated");
        })
        .catch((error) => {
          this.$emit('error', error);
        });

    },
  },
  mounted() {
    if (this.activescope && this.activescope.brand_id) {
      this.fetchProductGroups();
    }
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
