<template>
    <div>
        <draggable drag-class="dragClass" :options="{ forceFallback: true }" class="list-group my-4" :list="priorites"
            group="priorites">
            <div v-for="priorite in priorites">
                <div class="btn btn-secondary text-start my-2 w-100">
                    <div class="d-flex flex-row mt-2">
                        <h1 :class="priorite.color">{{ priorite.priority }}</h1>
                         <div class="d-flex flex-column">
                            <div class="d-flex flex-row">
                                <div>{{ priorite.autocare_p_a_d_b_meta_u_o_m_code_assignment.meta_u_o_m_codes.UOMDescription }} ( {{
                                    priorite.autocare_p_a_d_b_meta_u_o_m_code_assignment.meta_u_o_m_codes.UOMLabel }} )&nbsp;:&nbsp; </div>
                                <div v-for="metric in priorite.autocare_p_a_d_b_meta_u_o_m_code_assignment.meta_u_o_m_codes.lemnocity_u_o_m_metrics">
                                    <div v-for="measurement in metric" :class="priorite.color">
                                        {{ measurement.title }} </div>
                                </div>
                            </div>
                            <div> {{
                                priorite.autocare_p_a_d_b_meta_u_o_m_code_assignment.meta_u_o_m_codes.measurementgroup.MeasurementGroupName
                            }} </div>
                        </div>
                    </div>
                </div>
            </div>
        </draggable>
        <button class="btn btn-primary" @click="updateGroup"><i class="fa fa-floppy-disk"></i>
            Update</button>
    </div>
</template>
<style>
.dragClass {
    opacity: 1 !important;
    box-shadow: 0 .75em 2em rgba(0, 0, 0, .5), 0 .75em 3em rgba(0, 0, 0, .15);
}

.dragItem {
    width: 18rem;
    position: relative;
    cursor: grab;
}

.dragItem:active {
    cursor: grabbing;
}
</style>
<script>
import draggable from 'vuedraggable';

export default {
    props: { uomInformation: Array },
    components: {
        draggable,
    },
    data() {
        return {
            priorites: [],
            metricColor: null,
            localPriorites: [],
        }
    },
    watch: {
        uomInformation: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.priorites = JSON.parse(JSON.stringify(newVal)).map((element) => {
                const title = element.autocare_p_a_d_b_meta_u_o_m_code_assignment.meta_u_o_m_codes.lemnocity_u_o_m_metrics[0].measurement_system.title;
                var color = "metricColor"; 
                if(title === "Standard"){
                    color = "standardColor";
                }
                return {
                    ...element,
                    color: color,
                };
            });
            }
        }
    },
    methods: {
        async updateGroup() {
            this.priorites.forEach((priorite, index) => {
                priorite.priority = index + 1;
            })

            axios.post('/uom-priorites/update', [this.priorites])
                .then(response => {
                    //console.log(response.data);
                    if (response.data.error) {
                        this.$emit('message', ['errorMessage', response.data.error]);
                    } else {
                        //this.reactiveGroup = JSON.stringify(response.data.data);
                        this.$emit('message', ['successMessage', "Priorite updated"]);
                    }
                })
                .catch(error => {
                    this.$emit('message', ['Error', error]);
                });
        },
    }
}
</script>