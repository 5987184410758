<template>
	<fieldset>
		<div class="d-flex flex-row flex-wrap">
			<div v-if="attributes.length == 0">
				<h5>This Part does not have any attributes.</h5>
			</div>
			<div v-else class="fspanel" v-for="attribute in attributes" :key="attribute.id">
				<div class="inputrow">
					<div class="inputwrapper d-flex flex-column">
						<label>{{ attribute.PAName }}</label>
						<div v-if="setInput(attribute) != 'checkbox'" class="inputsection">
							<input :type="setInput(attribute)" v-model="attribute.value" @blur="autoSave(attribute)"
								@keyup.enter="autoSave(attribute)" />
							<div>{{ getType(attribute.part_attribute_assignments)[0] }}</div>
						</div>
						<div v-else class="inputsection">
							<div class="switch-wrapper">
								<label>{{ attribute.value ? 'Yes' : 'No' }}</label>
								<label class="switch">
									<input type="checkbox" v-model="attribute.value" @change="autoSave(attribute)">
									<span class="slider round"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash.debounce';

export default {
	props: ['attributes', 'previewPart'],
	data() {
		return {
			answer: '',
		};
	},
	watch: {
		attributes: {
			immediate: true,
			deep: true,
			handler(newVal) {
				//console.log(newVal);
			},
		},
		previewPart: {
			immediate: true,
			deep: true,
			handler(newVal) {
				if (newVal != null) {
					this.updateAttributes();
				}
			},
		},
	},
	methods: {
		getType(uomlabels) {
			const types = [];

			uomlabels.forEach(element => {
				element.meta_u_o_m_code_assignments.forEach(nestedEle => {
					types.push(nestedEle.meta_u_o_m_codes.UOMCode.toLowerCase());
				});
			});
			return types;
		},
		setInput(attribute) {
			if (attribute.PAName.includes('Included') || attribute.PAName.includes('Compatible')) {
				return 'checkbox';
			}
			if (attribute.part_attribute_assignments[0].meta_u_o_m_code_assignments.length == 0) {
				return 'text';
			} else {
				return 'number';
			}
		},
		async startForgeAttributes(attribute) {
			try {
				await axios.post('/part-forge/continue/attributes', {
					attribute_id: attribute.id,
					attribute_name: attribute.PAName,
					value: attribute.value,
					part_attribute_assignments: attribute.part_attribute_assignments,
				});
				this.$emit('success', attribute.PAName + " updated");
			} catch (error) {
				this.$emit('error', error);
			}
		},
		autoSave: debounce(function (attribute) {
			this.startForgeAttributes(attribute);
		}, 500),
		updateAttributes() {
			this.attributes.forEach(attribute => {
				const matchedAttribute = this.previewPart[0].part_attributes.find(
					partAttribute => partAttribute.padb_part_attribute_id === attribute.PAID
				);

				if (matchedAttribute) {
					attribute.value = matchedAttribute.value_bool || matchedAttribute.value_decimal || matchedAttribute.value_int || matchedAttribute.value_varchar;
				}
			});
		}

	},
};
</script>
<style scoped>
.switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #5c110f;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: #d69e2e;
}

input:focus + .slider {
	box-shadow: 0 0 1px #d69e2e;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>
