<template>
    <div>
        <div v-if="errorMessage" class="alert alert-danger"><i class="fa fa-triangle-exclamation mx-2"></i>{{ errorMessage
        }}</div>
        <div v-if="successMessage" class="alert alert-success"><i class="fa fa-circle-check mx-2"></i>{{ successMessage }}
        </div>
        <div class="d-flex flex-row flex-wrap">
            <div :list="localPartAttributeGroup" group="groups" class="d-flex flex-wrap">
                <div v-for="group in localPartAttributeGroup" :key="group.name" class="card m-2 dragItem">
                    <i v-if="group.sort_order != 1" @click="removeGroup(group)"
                        class="fa fa-minus d-flex flex-row-reverse m-2"></i>
                    <div>
                        <div class="card-body d-flex flex-column">
                            <h5 class="card-title">{{ group.title }}</h5>
                            <p class="card-text" id="attribute_description">{{ group.description }}</p>
                            <div class="card">
                                <members :group="group" :can-drag="canDrag"></members>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.dragItem {
    width: 18rem;
    position: relative;
}
</style>
<script>
import PartAttributesMembers from './PartAttributesMembers.vue';
export default {
    props: {
        partAttributeGroup: Array,
        canDrag: Boolean,
        canRemove: Boolean
    },
    components: {
        'members': PartAttributesMembers,
    },
    data() {
        return {
            localPartAttributeGroup: [],
            errorMessage: "",
            successMessage: "",
        }
    },
    watch: {
        partAttributeGroup: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.localPartAttributeGroup = JSON.parse(JSON.stringify(newVal));
            }
        },
    },
    methods: {
        removeGroup(group) {
            axios.post(`/part-attributes/removegroup`, group)
                .then(response => {
                    if (response.data.error) {
                        this.showAlert(response.data.error, 'errorMessage');
                    } else {
                        this.showAlert('Group Removed.', 'successMessage');
                    }
                })
                .catch(error => {
                    this.showAlert('Error: ' + error, 'errorMessage');
                });
        },
        showAlert(message, type) {
            this[type] = message;
            setTimeout(() => {
                this[type] = '';
            }, 3000);
            if ([type] == 'successMessage') {
                window.location.reload();
            }
        }
    }
}
</script>