/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('attribute-component', require('./components/PartAttributes/PartAttributesHome.vue').default);
Vue.component('attribute-component-edit', require('./components/PartAttributes/PartAttributes/PartAttributesEditGroup.vue').default);
Vue.component('attribute-component-add', require('./components/PartAttributes/PartAttributes/PartAttributesAddComponent.vue').default);
Vue.component('attribute-component-remove', require('./components/PartAttributes/PartAttributes/PartAttributesRemove.vue').default);
Vue.component('product-group-component', require('./components/ProductGroups/ProductGroupsHome.vue').default);
Vue.component('product-subgroup-component', require('./components/ProductSubGroups/ProductSubGroupsHome.vue').default);
Vue.component('uom-home', require('./components/UOMPriorities/UOMPrioritiesHome.vue').default);
Vue.component('file-upload', require('./components/FileUpload/FileUploadComponent.vue').default);
Vue.component('part-forge', require('./components/PartForge/PartForgeHome.vue').default);
Vue.component('basic-information', require('./components/PartForge/Components/BasicInformation.vue').default);
Vue.component('application', require('./components/PartForge/Components/Application.vue').default);
Vue.component('position', require('./components/PartForge/Components/Position.vue').default);
Vue.component('part-attributes', require('./components/PartForge/Components/PartAttributes.vue').default);
Vue.component('part-forge-main', require('./components/PartForge/Components/PartForgeMain.vue').default);
Vue.component('dashboard', require('./components/Dashboard/DashboardHome.vue').default);
Vue.component('sidenav', require('./components/SideNavBar.vue').default);
Vue.component('header-component', require('./components/HeaderComponent.vue').default);
Vue.component('part-forge-history', require('./components/PartForge/Components/PartForgeHistory.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
