<template>
    <div>
        <div v-if="errorMessage" class="alert alert-danger"><i class="fa fa-triangle-exclamation mx-2"></i>{{
            errorMessage
            }}</div>
        <div v-if="successMessage" class="alert alert-success"><i class="fa fa-circle-check mx-2"></i>{{ successMessage
            }}
        </div>
        <ForgeTabs :tabs="tabs">
            <template v-slot:main>
                <fieldset>
                    <part-forge-main :parts="parts" :productgroups="productGroup" :productsubgroup="productSubgroup"
                        :activescope="activescope" :saas="saas" :previewPart="previewPart" @error="handleError"
                        @success="handleSuccess"/>
                    <application :previewPart="previewPart" @error="handleError"
                    @success="handleSuccess"/>
                </fieldset>
            </template>
            <template v-slot:part_attributes>
                <part-attributes :attributes="attributes" :previewPart="previewPart" @error="handleError"
                @success="handleSuccess"/>
            </template>
            <template v-slot:media>
                <part-forge-media :previewPart="previewPart" @error="handleError"
                @success="handleSuccess"/>
            </template>
            <template v-slot:description>
                <basic-information :base="basicData" :previewPart="previewPart" @error="handleError"
                    @success="handleSuccess" />
            </template>
            <template v-slot:pricing>
                <part-forge-pricing :previewPart="previewPart" @error="handleError"
                @success="handleSuccess"/>
            </template>
            <template v-slot:application_fitment>
                <position :positions="positions" :previewPart="previewPart" @error="handleError"
                @success="handleSuccess"/>
            </template>
            <template v-slot:kit>
                <part-forge-kit :previewPart="previewPart" @error="handleError"
                @success="handleSuccess"/>
            </template>
            <template v-slot:project_management>
                <part-forge-project-management :parts="parts" :productgroups="productGroup"
                    :productsubgroup="productSubgroup" :activescope="activescope" :saas="saas"
                    :previewPart="previewPart" />
            </template>
            <template v-slot:preview>
                <part-forge-preview :previewPart="previewPart" />
            </template>
        </ForgeTabs>
    </div>
</template>
<script>
import BasicInformation from "./Components/BasicInformation.vue";
import Application from "./Components/Application.vue";
import Position from "./Components/Position.vue";
import PartAttributes from "./Components/PartAttributes.vue";
import ForgeTabs from "./Components/ForgeTabs.vue";
import PartForgeMain from "./Components/PartForgeMain.vue";
import PartForgeMedia from "./Components/PartForgeMedia.vue";
import PartForgePricing from "./Components/PartForgePricing.vue";
import PartForgeKit from "./Components/PartForgeKit.vue";
import PartForgeProjectManagement from "./Components/PartForgeProjectManagement.vue";
import PartForgePreview from "./Components/PartForgePreview.vue";

export default {
    props: {
        parts: {
            type: Array,
            required: true,
        },
        productGroup: {
            type: [Array, Object], // Adjust based on your actual expected type
            default: null,
        },
        productSubgroup: {
            type: [Array, Object], // Adjust based on your actual expected type
            default: null,
        },
        saas: {
            type: [Array, Object]
        },
        previewPart: {
            type: [Array, Object], // Adjust based on your actual expected type
            default: null,
        }
    },
    components: {
        ForgeTabs,
        BasicInformation,
        Application,
        Position,
        PartAttributes,
        PartForgeMain,
        PartForgeMain,
        PartForgeMedia,
        PartForgePricing,
        PartForgeKit,
        PartForgeProjectManagement,
        PartForgePreview
    },
    data() {
        return {
            basicData: {
                'product-groups': [],
                'sub-groups': [],
                'dev-phase': '',
                'partID': '',
                'identifier': '',
                'code': '',
                'description': []
            },
            positions: [],
            attributes: [],
            tabs: [
                { name: 'main', label: 'Main', icon: "" },
                { name: 'part_attributes', label: 'Attributes', icon: "fa-solid fa-ruler-combined" },
                { name: 'media', label: 'Media', icon: "fa-solid fa-photo-film" },
                { name: 'description', label: 'Description', icon: "fa-solid fa-book" },
                { name: 'pricing', label: 'Pricing', icon: "fa-solid fa-dollar-sign" },
                { name: 'application_fitment', label: 'Application & Fitment', icon: "fa-solid fa-screwdriver-wrench" },
                { name: 'kit', label: 'Kit Components', icon: "fa-solid fa-object-group" },
                { name: 'project_management', label: 'Project Management', icon: "fa-solid fa-list-check" },
                { name: 'preview', label: 'Preview', icon: "fa-solid fa-eye" },
            ],
            activescope: [],
            errorMessage: null,
            successMessage: null,
        }
    },
    comments: {
        'basic-information': BasicInformation,
        'application': Application,
        'position': Position,
        'part-attributes': PartAttributes,
    },
    watch: {
        parts: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.basicData['partID'] = newVal[0]['PartTerminologyID'];
                this.basicData['description'] = newVal[0]['part_description'];
                this.positions = newVal[0]['positions'];
                this.attributes = newVal[0]['padb_part_attributes'];
            }
        },
        productGroup: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.basicData['product-groups'] = newVal;
            }
        },
        productSubgroup: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.basicData['sub-groups'] = newVal;
            }
        },
        saas: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal.active_scope != null) {
                    this.activescope = newVal.active_scope;
                }
            }
        },
        previewPart: {
            immediate: true,
            deep: true,
            handler(newVal) {
                //console.log(newVal);
            }
        }
    },
    methods: {
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    }
  },
}
</script>