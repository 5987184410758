<template>
  <div class="container-content">
    <div class="row">
      <div class="col-sm mb-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title float-left m-0">Leads</h4>
          </div>
          <div class="card-body">
            <h2>{{ totalLeadsCount }}</h2>
            <small>Total Leads</small>
          </div>
        </div>
      </div>
      <div class="col-sm mb-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title float-left m-0">Deals</h4>
          </div>
          <div class="card-body">
            <h2>{{ totalDealsCount }}</h2>
            <small>Total Deals</small>
          </div>
        </div>
      </div>
      <div class="col-sm mb-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title float-left m-0">People</h4>
          </div>
          <div class="card-body">
            <h2>{{ totalPeopleCount }}</h2>
            <small>Total People</small>
          </div>
        </div>
      </div>
      <div class="col-sm mb-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title float-left m-0">Organizations</h4>
          </div>
          <div class="card-body">
            <h2>{{ totalOrganisationsCount }}</h2>
            <small>Total Organizations</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title m-0">Created Last 14 Days</h4>
          </div>
          <div class="card-body">
            <canvas
              id="createdLast14Days"
              style="height:500px; width:100%"
              ref="createdLast14DaysCanvas"
            ></canvas>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title m-0">Users Online</h4>
          </div>
          <div class="card-body" style="height:523px; width:100%">
            <div
              v-for="(user, index) in usersOnline"
              :key="index"
              :class="['media', { 'mb-3': index !== usersOnline.length - 1 }]"
            >
              <span
                class="fa fa-user fa-2x mr-3 border rounded-circle p-2"
                aria-hidden="true"
              ></span>
              <div class="media-body">
                <h4 class="mt-1 mb-0">{{ user.name }}</h4>
                <p class="mb-0">{{ formatLastOnline(user.last_online_at) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';

// Register the necessary components and date adapter
Chart.register(...registerables);

export default {
  props: {
    totalLeadsCount: {
      type: Number,
      default: 0
    },
    totalDealsCount: {
      type: Number,
      default: 0
    },
    totalPeopleCount: {
      type: Number,
      default: 0
    },
    totalOrganisationsCount: {
      type: Number,
      default: 0
    },
    createdLast: {
      type: String,
      default: ''
    },
    usersOnline: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatLastOnline(dateTime) {
      return new Date(dateTime).toLocaleString();
    },
    renderChart() {
      const ctx = this.$refs.createdLast14DaysCanvas.getContext('2d');
      
      // Parse the string data into JSON
      const data = JSON.parse(this.createdLast);

      // Parse the dates and values
      const dates = Object.keys(data);
      const leads = dates.map(date => data[date].daily.leads);
      const deals = dates.map(date => data[date].daily.deals);

      // Debugging logs
      //console.log('Parsed Dates:', dates);
      //console.log('Leads Data:', leads);
      //console.log('Deals Data:', deals);

      // Convert dates to JavaScript Date objects
      const formattedDates = dates.map(date => new Date(date.replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')));

      new Chart(ctx, {
        type: 'line',
        data: {
          labels: formattedDates,
          datasets: [
            {
              label: 'Leads',
              data: leads,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              fill: false
            },
            {
              label: 'Deals',
              data: deals,
              borderColor: 'rgba(153, 102, 255, 1)',
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              fill: false
            }
          ]
        },
        options: {
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                tooltipFormat: 'MMM d',
                displayFormats: {
                  day: 'MMM d'
                }
              },
              title: {
                display: true,
                text: 'Date'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Count'
              },
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            }
          }
        }
      });
    }
  },
  mounted() {
    this.renderChart();
  }
};
</script>