<template>
    <div>
        <div v-if="errorMessage" class="alert alert-danger"><i class="fa fa-triangle-exclamation mx-2"></i>{{
            errorMessage
        }}</div>
        <div v-if="successMessage" class="alert alert-success"><i class="fa fa-circle-check mx-2"></i>{{ successMessage
            }}
        </div>
        <div v-if="currentStep == 1">
            <form @submit.prevent="saveStep1" class="card m-2 w-50">
                <div class="card border-0 mx-2 mt-1">
                    <h1>Step 1</h1>
                    <p>Create a Part Number Scheme. Description is not required fields.
                    </p>
                    <div>
                        <div class="d-flex my-1">
                            <label class="align-self-lg-center">*Parent Company:</label>
                            <div v-if="ParentCompanies.length > 0" class="d-flex ">
                                <select v-model="step1.parent_company_id" id="parent_company_id"
                                    name="parent_company_id" class="xxlong">
                                    <option v-for="company in ParentCompanies" v-bind:value="company.id">{{
            company.parent_company_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex my-1">
                            <label class="align-self-lg-center">*Brand:</label>
                            <div v-if="UserBrands.length > 0" class="d-flex ">
                                <select v-model="step1.brand_id" id="parent_company_id" name="parent_company_id"
                                    class="xxlong">
                                    <option v-for="brand in UserBrands" v-bind:value="brand.id">{{ brand.brand_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex my-1">
                            <label class="align-self-lg-center">*Title:</label>
                            <input required v-model="step1.title" name="title" placeholder="Title" class="xxlong" />
                        </div>
                        <div class="d-flex my-1">
                            <label>Description:</label>
                            <textarea v-model="step1.description" name="description" placeholder="Description"
                                class="small"></textarea>
                        </div>
                    </div>
                    <div class="d-flex my-2">
                        <label>OR Select Existing Title and Description: </label>
                        <select v-model="ExistingStep1" id="part_scheme" name="part_scheme" class="xxxlong">
                            <option v-for="partscheme in PartSchemes">Title: {{ partscheme.title }} | Description: {{
            partscheme.description }}</option>
                        </select>
                    </div>
                </div>
                <div class="card-footer text-sm-end">
                    <button class="btn btn-light">
                        <i class="fa fa-floppy-disk"></i> Save Schema
                    </button>
                </div>
            </form>
        </div>
        <div v-if="currentStep == 2">
            <form @submit.prevent="saveStep2" class="card m-2">
                <h1>Step 2</h1>
                <p>Create or add a Product Group. The only required field is Part Number Scheme ID.</p>

                <div class="card border-0">
                    <div>
                        <div class="d-flex justify-content-around">
                            <div class="w-75">
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">*Part Number Scheme ID:</label>
                                    <input v-model="newPartNumberSchemeID" class="my-2 w-50 py-2" type="text"
                                        id="partNumberSchema" placeholder="Part Number Scheme ID" disabled>
                                </div>
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Description:</label>
                                    <textarea v-model="step2.description" name="description" placeholder="Description"
                                        class="w-50"></textarea>
                                </div>
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Sub-Brands:</label>
                                    <select v-model="step2.subbrand" class="my-2 w-50 py-2">
                                        <option disabled value="">Please select one</option>
                                        <option v-for="item in subBrands" :value="item.id">{{ item.subbrand_name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2">Active:</label>
                                    <input disabled v-model="step2.active" class="my-2 py-2" type="checkbox" id="active"
                                        placeholder="Active">
                                    <label class="align-self-lg-center mx-2">Allow New Parts:</label>
                                    <input v-model="step2.newParts" class="my-2 py-2" type="checkbox" id="allow"
                                        placeholder="Allow New Parts">
                                </div>
                            </div>
                            <div class="w-50">
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Part Number Segment:</label>
                                    <input v-model="step2.segmentCode" onchange="enableActive()" class="my-2 py-2"
                                        style="width: 37%;" type="text" id="partNumberSegement"
                                        placeholder="Part Number Segment Code">
                                </div>
                                <div v-if="exitingSegmentCode.length > 0" class="d-flex ">
                                    <label class="align-self-lg-center mx-2 w-25">OR Select Existing Code: </label>
                                    <select v-model="step2.segmentCode" id="part_scheme_code" name="part_scheme_code"
                                        class="w-50" onchange="enableActive()">
                                        <option v-for="code in exitingSegmentCode">{{ code.part_number_segment_code }}
                                        </option>
                                    </select>
                                </div>
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Title:</label>
                                    <input v-model="step2.title" name="title" placeholder="Title" class="w-50 my-2 py-2"
                                        required />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-sm-end">
                    <button class="btn btn-light">
                        <i class="fa fa-floppy-disk"></i> Save Product Group
                    </button>
                </div>
            </form>
        </div>

        <!--<div v-if="currentStep == 3">
            <h1>Step 3</h1>
            <div class="d-flex flex-row">
                <div class="card w-50 mx-5">
                    <div class="card-header">Step 1</div>
                    <div>
                        <div class="d-flex">
                            <label class="align-self-lg-center mx-2 w-25">Part Number Scheme ID:</label>
                            <input disabled v-model="newPartNumberSchemeID" class="my-2 w-50 py-2" type="text" id="title"
                                placeholder="Title">
                        </div>
                        <div class="d-flex">
                            <label class="align-self-lg-center mx-2 w-25">Title:</label>
                            <input disabled v-model="step1.title" class="my-2 w-50 py-2" type="text" id="title"
                                placeholder="Title">
                        </div>
                        <div class="d-flex">
                            <label class="align-self-lg-center mx-2 w-25">Description:</label>
                            <input disabled v-model="step1.description" class="my-2 w-50 py-2" type="text" id="description"
                                placeholder="Description">
                        </div>
                    </div>
                </div>
                <div class="card w-50 mx-5">
                    <div class="card-header">Step 2</div>
                    <div>
                        <div class="d-flex justify-content-around">
                            <div class="w-50">
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Part Number Scheme ID:</label>
                                    <input disabled v-model="newPartNumberSchemeID" class="my-2 w-50 py-2" type="text"
                                        id="partNumberSchema" placeholder="Part Number Scheme ID">
                                </div>
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Sub-Brand ID:</label>
                                    <input disabled v-model="step2.subbrand" class="my-2 w-50 py-2" type="text"
                                        id="sub-brand" placeholder="sub-brand">
                                </div>
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Description:</label>
                                    <input disabled v-model="step2.description" class="my-2 w-50 py-2" type="text"
                                        id="description" placeholder="Description">
                                </div>
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2">Active:</label>
                                    <input disabled v-model="step2.active" class="my-2 py-2" type="checkbox" id="active"
                                        placeholder="Active">
                                    <label class="align-self-lg-center mx-2">Allow New Parts:</label>
                                    <input disabled v-model="step2.newParts" class="my-2 py-2" type="checkbox" id="allow"
                                        placeholder="Allow New Parts">
                                </div>
                            </div>
                            <div class="w-50">
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Part Number Segment Code:</label>
                                    <input disabled v-model="step2.segmentCode" class="my-2 w-50 py-2" type="text"
                                        id="partNumberSegement" placeholder="Part Number Segment Code">
                                </div>
                                <div class="d-flex">
                                    <label class="align-self-lg-center mx-2 w-25">Title:</label>
                                    <input disabled v-model="step2.title" class="my-2 w-50 py-2" type="text" id="title"
                                        placeholder="Title">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mx-5 my-2">
                <div class="my-2">
                    <h3>Assign a part to the Product Group. Adding a part is not required. This step can be skipped.</h3>
                    <div class="d-flex justify-content-around">
                        <div class="d-flex w-50">
                            <label class="text-light align-self-lg-center my-2">Category:</label>
                            <input class="my-2 w-75 py-2" type="text" id="searchInput" placeholder="Search category..."
                                onkeyup="showSuggestions(this.value)">
                        </div>
                        <div class="d-flex w-50">
                            <label class="text-light align-self-lg-center mx-2">Sub-Category:</label>
                            <input class="my-2 w-75 py-2" type="text" id="searchInput2" placeholder="Search subcategory..."
                                onkeyup="showSuggestions2(this.value)">
                        </div>
                        <div class="d-flex w-50">
                            <label class="text-light align-self-lg-center mx-2">Part:</label>
                            <input class="my-2 w-75 py-2" type="text" id="searchInput3" placeholder="Select part..."
                                onkeyup="showSuggestions3(this.value)">
                        </div>
                    </div>
                </div>
                <div class="card-body mx-5">
                    <div class="col-4">
                        <div id="suggestionContainer" class="bg-light border-1 border-primary"></div>
                    </div>
                    <div class="col-4">
                        <div id="suggestionContainer2" class="bg-light border-1 border-primary"></div>
                    </div>
                    <div class="col-4">
                        <div id="suggestionContainer3" class="bg-light border-1 border-primary"></div>
                    </div>
                    <div v-if="part != null">
                        <div class="d-flex w-50">
                            <label class="align-self-lg-center mx-2 w-25">Part Number:</label>
                            <input disabled v-model="partID" class="my-2 w-50 py-2" type="text" id="Part_ID" placeholder="Part ID">
                            <span class="d-none">
                                <input id="group" value="productGroup" />
                            </span>
                            <button class="btn btn-info mx-3 my-3 h-75" @click="saveStep3()">
                                <i class="fa fa-floppy-disk"></i> Save
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-sm-end">

                    <button class="btn btn-light" @click="skipStep3()">
                        <i class="fa-solid fa-forward"></i> Skip
                    </button>
                </div>
            </div>
        </div>-->

    </div>
</template>
<script>
import axios from 'axios';

export default {
    props: ['part', 'step', 'brand', 'PartSchemes', 'ParentCompanies', 'ProductGroups', 'UserBrands', 'OrganizationID'],
    data() {
        return {
            errorMessage: "",
            successMessage: "",
            currentStep: null,
            ownedProductGroups: [],
            step1: {
                title: "",
                description: "",
                brand_id: "",
                parent_company_id: ""
            },
            newPartNumberSchemeID: null,
            step2: {
                segmentCode: "",
                description: "",
                subbrand: "",
                title: "",
                active: "",
                newParts: "",
                newPartNumberSchemeID: null,
                brand_id: "",
                parent_company_id: ""
            },
            subBrands: [],
            partID: null,
            step3: {
                pcdb_part_terminology_id: null,
                product_group_id: null,
                brand_id: null,
                subbrand_id: null,
                title: null,
                description: null,
                parent_company_id: null,
            },
            currentBrand: null,
            ExistingStep1: null,
            exitingSegmentCode: [],
            isLimnocity: false,
        }
    },
    watch: {
        newPartNumberSchemeID(newValue) {
            this.step2.newPartNumberSchemeID = newValue;
        },
        ExistingStep1(newValue) {
            // Define regular expressions for title and description
            const titleRegex = /Title: (.+?) \|/;
            const descriptionRegex = /Description: (.+)/;

            // Use match method to extract values
            const titleMatch = newValue.match(titleRegex);
            const descriptionMatch = newValue.match(descriptionRegex);

            // Check if matches were found
            if (titleMatch && descriptionMatch) {
                const title = titleMatch[1].trim();
                const description = descriptionMatch[1].trim();

                this.step1.title = title;
                this.step1.description = description;
            } else {
                const title = titleMatch[1].trim();
                this.step1.title = title;
                this.step1.description = "";
            }
        },
        step1: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal != null) {
                    if (newVal.brand_id != "") {
                        this.step2.brand_id = newVal.brand_id;
                    }
                    if (newVal.parent_company_id != "") {
                        this.step2.parent_company_id = newVal.parent_company_id;
                        this.step3.parent_company_id = newVal.parent_company_id;
                    }
                }
            }
        },
        partID: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal != null) {
                    this.step3.pcdb_part_terminology_id = newVal;
                }
            }
        },
        ParentCompanies: {
            immediate: true,
            deep: true,
            handler(newVal) {
                newVal.forEach(pc => {
                    if (pc.organization_id == 1) {
                        this.isLimnocity = true;
                    }
                });

            }
        }
    },
    mounted() {
        this.currentStep = this.step;

        if (this.part[0]) {
            this.partID = this.part[0]['pcdb_part_terminology_id'];
        }
        if (this.step != 1 | this.step != 2) {
            if (sessionStorage.getItem('Newly_Created_Part_Number')) {
                const newData = sessionStorage.getItem('Newly_Created_Part_Number');
                if (newData) {
                    const parsedData = JSON.parse(newData);
                    this.step1.title = parsedData.title;
                    this.step2.description = parsedData.description;
                }
                sessionStorage.removeItem('Newly_Created_Part_Number');
            }
            if (sessionStorage.getItem('Newly_Created_Product_Group')) {
                const newData = sessionStorage.getItem('Newly_Created_Product_Group');
                if (newData) {
                    const parsedData = JSON.parse(newData);
                    this.step2.active = parsedData.active;
                    this.step2.newParts = parsedData.allow_new_parts;
                    this.step2.brand_id = parsedData.brand_id;
                    this.step2.description = parsedData.description;
                    this.step2.segmentCode = parsedData.part_number_segment_code;
                    this.step2.subbrand = parsedData.subbrand_id;
                    this.step2.title = parsedData.title;
                    this.step3.pcdb_part_terminology_id = parsedData.pcdb_part_terminology_id;
                    this.step3.brand_id = parsedData.brand_id;
                    this.step3.subbrand_id = parsedData.subbrand_id;
                    this.step3.title = parsedData.title;
                    this.step3.description = parsedData.description;
                    this.step3.product_group_id = parsedData.id;
                    this.step3.parent_company_id = parsedData.parent_company_id;
                    this.newPartNumberSchemeID = parsedData.part_number_scheme_id;
                }
                sessionStorage.removeItem('Newly_Created_Product_Group');
            }
        }
    },
    methods: {
        saveStep1() {
            axios.post(`/brands/part-number/add`, this.step1)
                .then(response => {
                    if (sessionStorage.getItem('Newly_Created_Part_Number')) {
                        sessionStorage.removeItem('Newly_Created_Part_Number');
                        sessionStorage.setItem('Newly_Created_Part_Number', JSON.stringify(response.data.data));
                    } else {
                        sessionStorage.setItem('Newly_Created_Part_Number', JSON.stringify(response.data.data));
                    }
                    if (response.data.step != this.step) {
                        this.newPartNumberSchemeID = response.data.data.id;
                        this.currentStep = response.data.step;
                        this.subBrands = response.data.subBrands;
                        this.exitingSegmentCode = response.data.ownedGroups;
                    };
                    if (response.data.error) {
                        this.showAlert(response.data.error, 'errorMessage');
                    } else {
                        this.showAlert('Part Number Schema Added.', 'successMessage');
                    }
                })
                .catch(error => {
                    this.showAlert('Error: ' + error, 'errorMessage');
                });
        },
        saveStep2() {
            if (this.isLimnocity) {
                axios.post(`/brands/part-group/add`, this.step2)
                    .then(response => {
                        if (sessionStorage.getItem('Newly_Created_Product_Group')) {
                            sessionStorage.removeItem('Newly_Created_Product_Group');
                            sessionStorage.setItem('Newly_Created_Product_Group', JSON.stringify(response.data.data));
                        } else {
                            sessionStorage.setItem('Newly_Created_Product_Group', JSON.stringify(response.data.data));
                        }
                        if (response.data.step != this.step) {
                            this.currentStep = response.data.step;
                        };
                        if (response.data.error) {
                            this.showAlert(response.data.error, 'errorMessage');
                        } else {
                            this.showAlert('Product Group Added.', 'successMessage');
                        }

                    })
                    .catch(error => {
                        this.showAlert('Error: ' + error, 'errorMessage');
                    });
            } else {
                axios.post(`/tenant/brands/part-group/add`, this.step2)
                    .then(response => {
                        if (sessionStorage.getItem('Newly_Created_Product_Group')) {
                            sessionStorage.removeItem('Newly_Created_Product_Group');
                            sessionStorage.setItem('Newly_Created_Product_Group', JSON.stringify(response.data.data));
                        } else {
                            sessionStorage.setItem('Newly_Created_Product_Group', JSON.stringify(response.data.data));
                        }
                        if (response.data.step != this.step) {
                            this.currentStep = response.data.step;
                        };
                        if (response.data.error) {
                            this.showAlert(response.data.error, 'errorMessage');
                        } else {
                            sessionStorage.removeItem('Newly_Created_Product_Group');
                            this.showAlert('Product Group Added. Redirectly you back to Product Groups Home page', 'successMessage');
                            setTimeout(() => {
                                window.location.href = `/tenant/brands/product-groups/${this.step2.brand_id}`;
                            }, 1000);
                        }

                    })
                    .catch(error => {
                        this.showAlert('Error: ' + error, 'errorMessage');
                    });
            }
        },
        showAlert(message, type) {
            this[type] = message;
        },
        skipStep3() {
            window.location.href = '/brands/product-groups/' + this.brand;
        },
        saveStep3() {
            axios.post(`/brands/part-group/update`, this.step3)
                .then(response => {
                    if (response.data.error) {
                        this.showAlert(response.data.error, 'errorMessage');
                    } else {
                        this.showAlert('Part added to product group.', 'finalsuccessMessage');
                        setTimeout(() => {
                            window.location.href = '/brands/product-groups/' + response.data.data.brand_id;
                        }, 3000);
                    }
                })
                .catch(error => {
                    this.showAlert('Error: ' + error, 'errorMessage');
                });
        },
    },
}
</script>