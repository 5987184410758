<template>
  <fieldset>
    <div>
      <div class="inputrow">
        <div class="inputwrapper full-width">
          <label for="part__description">Description</label>
          <div>
            <textarea @keyup.enter="autoSave" v-model="description" name="part__description" id="part__description"
              row="" cols="" class="medium"></textarea>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script>
import axios from 'axios';
import debounce from 'lodash.debounce';

export default {
  props: {
    base: {
      type: Object
    },
    previewPart: {
      type: [Object, Array],
      default: null,
    }
  },
  data() {
    return {
      description: '',
      parent_company_id: '',
      brand_owner_id: '',
      brand_id: '',
      product_group_id: '',
      product_subgroup_id: '',
      pcdb_part_terminology_id: '',
      title: '',
      product_identifier: '',
      variant_code: '',
      development_phase: '',
      limnocity_description: false,
      lemnocity_position: null,
    }
  },
  watch: {
    previewPart: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.parent_company_id = newVal[0]['parent_company_id'];
        this.brand_owner_id = newVal[0]['brand_owner_id'];
        this.brand_id = newVal[0]['brand_id'];
        this.product_group_id = newVal[0]['product_group_id'];
        this.product_subgroup_id = newVal[0]['product_subgroup_id'];
        this.pcdb_part_terminology_id = newVal[0]['pcdb_part_terminology_id'];
        this.title = newVal[0]['title'];
        this.product_identifier = newVal[0]['product_identifier'];
        this.variant_code = null;
        this.development_phase = newVal[0]['development_stage_id'];
        if (newVal[0]['description'] != null) {
          this.description = newVal[0]['description'];
          this.limnocity_description = true;
        }
        if (newVal[0]['position_id'] != null) {
          this.lemnocity_position = newVal[0]['position_id'];
        }
        if (newVal[0]['development_phase_id'] != null) {
          this.developmentPhase = newVal[0]['development_phase_id'];
        }
      }
    },
    base: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (!this.limnocity_description) {
          this.description = newVal.description.PartsDescription;
        }
      }
    },
  },
  methods: {
    async startForgeProcess() {
      var partData = {
        parent_company_id: this.parent_company_id,
        brand_owner_id: this.brand_owner_id,
        brand_id: this.brand_id,
        product_group_id: this.product_group_id,
        product_subgroup_id: this.product_subgroup_id,
        pcdb_part_terminology_id: this.pcdb_part_terminology_id,
        title: this.title,
        product_identifier: this.productIdentifier,
        variant_code: this.variantCode,
        development_phase: this.developmentPhase,
        description: this.description,
        position: this.lemnocity_position,
      }
      try {

        const response = await axios.post('/tenant/part-forge/update', partData);
        if (response.data.success) {
          this.$emit('success', "Description Updated");
        }
      } catch (error) {
        this.$emit('error', error);
      }
    },
    autoSave() {
      this.startForgeProcess();
    },
  }
}
</script>