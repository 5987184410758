<template>
    <div>
        <div v-if="orderMessage" class="alert alert-warning"><i class="fa fa-circle-info mx-2"></i>{{ orderMessage }}
            <button class="btn btn-primary" style="height: 35px;" @click="saveGroupOrder"><i
                    class="fa fa-floppy-disk mx-2"></i>Save Group Order</button>
        </div>
        <div v-if="errorMessage" class="alert alert-danger"><i class="fa fa-triangle-exclamation mx-2"></i>{{ errorMessage
        }}</div>
        <div v-if="successMessage" class="alert alert-success"><i class="fa fa-circle-check mx-2"></i>{{ successMessage }}
        </div>
        <div class="d-flex flex-row flex-wrap">
            <draggable :draggable="canDrag" drag-class="dragClass" :options="{ forceFallback: true }"
                :list="localPartAttributeGroup" group="groups" class="d-flex flex-wrap">
                <div v-for="group in localPartAttributeGroup" :key="group.name" class="card m-2 dragItem">
                    <div>
                        <div class="card-body d-flex flex-column">
                            <h5 class="card-title">{{ group.title }}</h5>
                            <p class="card-text" id="attribute_description">{{ group.description }}</p>
                            <div class="card">
                                <members :group="group" :canDrag="canDrag"></members>
                            </div>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template>
<style>
.dragItem {
    width: 18rem;
    position: relative;
}
</style>

<script>
import PartAttributesMembers from "./PartAttributes/PartAttributesMembers.vue";
export default {
    props: ['partAttributeGroup', 'canDrag'],
    components: {
        'members': PartAttributesMembers,
    },
    data() {
        return {
            localPartAttributeGroup: [],
            errorMessage: "",
            successMessage: "",
            orderMessage: "",
        }
    },
    watch: {
        partAttributeGroup: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.localPartAttributeGroup = JSON.parse(JSON.stringify(newVal));
            }
        },
    },
}
</script>
