<template>
    <div>
        <div class="card">
            <div class="card-header bg-primary text-white">
                Part Forge History
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Part ID</th>
                        <th>Part Name</th>
                        <th>Description</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Forge started</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(session, index) in processedSessions" :key="index" @click="handleRowClick(session.id)">
                        <td>{{ session.partTerminologyID }}</td>
                        <td>{{ session.partTerminologyName }}</td>
                        <td>{{ session.partsDescription }}</td>
                        <td>{{ session.created_at }}</td>
                        <td>{{ session.updated_at }}</td>
                        <td>{{ hasStarted }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        partforgesession: {
            type: [Array, Object],
            required: true
        }
    },
    data() {
        return {
            processedSessions: [],
            csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'), // Assuming CSRF token is stored in a meta tag
            hasStarted: false,
            partId: null,
            product_group_id: null,
            product_subgroupd_id: null,
            forge_id: null,
        }
    },
    created() {
        this.processSessions();
      
    },
    methods: {
        processSessions() {
            const dateFormatter = new Intl.DateTimeFormat('en-US', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit'
            });

            let sessionsArray = [];


            if (Array.isArray(this.partforgesession)) {
                sessionsArray = this.partforgesession;
            } else if (typeof this.partforgesession === 'object') {
                sessionsArray = [this.partforgesession];
            }

            if(this.partforgesession.product_group_id != null){
                this.product_group_id = this.partforgesession.product_group_id;
            }
            if(this.partforgesession.product_subgroupd_id != null){
                this.product_subgroupd_id = this.partforgesession.product_subgroupd_id;
            }


            this.processedSessions = sessionsArray.map(session => {
                let partData = null;
                try {
                    partData = JSON.parse(session.part_data);
                } catch (e) {
                    console.error('Error parsing part_data:', e);
                    return null;
                }

                if (this.partforgesession.product_group_id != null) {
                    this.hasStarted = true;
                    this.partId = partData[0].PartTerminologyID;
                }

                this.forge_id = session.id;
                return {
                    id: session.id,
                    partTerminologyID: partData[0].PartTerminologyID,
                    partTerminologyName: partData[0].PartTerminologyName,
                    partsDescription: partData[0].part_description.PartsDescription,
                    created_at: dateFormatter.format(new Date(session.created_at)),
                    updated_at: dateFormatter.format(new Date(session.updated_at))
                };
            }).filter(session => session !== null); // Filter out any null values resulting from JSON parse errors
        },
        async handleRowClick(id) {
            if (!this.hasStarted) {
                try {
                    const response = await axios.post('/part-forge/continue', { id }, {
                        headers: {
                            'X-CSRF-TOKEN': this.csrfToken
                        }
                    });
                    if (response.data.redirect_url) {
                        window.location.href = response.data.redirect_url; // Redirect to the response URL
                    }
                } catch (error) {
                    console.error('Error continuing your forge:', error);
                }
            }
            else {
                window.location.href = `/forge_direct?parts=${this.partId}&productGroup=${this.product_group_id}&productSubgroup=${this.product_subgroupd_id}&forge_id=${this.forge_id}`;
            }
        }
    }
}
</script>

<style scoped>
.card {
    margin: 20px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.card-header {
    padding: 10px;
    font-size: 1.25em;
    font-weight: bold;
}

.table {
    width: 100%;
    margin: 0;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    cursor: pointer;
}

.table th {
    background-color: #f8f9fa;
    font-weight: bold;
    text-align: left;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.table-striped tbody tr:hover {
    background-color: #f1f1f1;
}
</style>
