<template>
	<div>
		<div class="inputrow">
			<div class="inputwrapper full-width">
				<label>Purpose <a href="#" id="part_purpose_opener"><span
							class="fa-solid fa-question-circle"></span></a></label>

				<!-- These are custom properties we will develop. -->
				<div id="part_purpose_group">
					<input type="radio" v-model="purpose" name="part_purpose_id" id="part_purpose_id__100" value="100"
						@change="autoSave" />
					<label for="part_purpose_id__100">One-Off/Custom</label>

					<input type="radio" v-model="purpose" name="part_purpose_id" id="part_purpose_id__101" value="101"
						@change="autoSave" />
					<label for="part_purpose_id__101">OEM Replacement</label>

					<input type="radio" v-model="purpose" name="part_purpose_id" id="part_purpose_id__102" value="102"
						@change="autoSave" />
					<label for="part_purpose_id__102">Aftermarket</label>

					<input type="radio" v-model="purpose" name="part_purpose_id" id="part_purpose_id__103" value="103"
						@change="autoSave" />
					<label for="part_purpose_id__103">Historical</label>

					<input type="radio" v-model="purpose" name="part_purpose_id" id="part_purpose_id__104" value="104"
						@change="autoSave" />
					<label for="part_purpose_id__104">Historical Reproduction</label>
				</div>

			</div>
		</div>

		<div class="inputrow">
			<div class="inputwrapper full-width">
				<p><em>TODO: select applicable year/make/model set(s) and replaced parts</em></p>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import debounce from 'lodash.debounce';

export default {
	props: {
		previewPart: {
			type: [Object, Array],
			default: null,
		}
	},
	data() {
		return {
			purpose: 0,
			forgeKey: null,
		}
	},
	watch: {
		previewPart: {
			immediate: true,
			deep: true,
			handler(newVal) {
				if (newVal != null) {
				this.purpose = newVal[0]['part_purpose_id'];
				this.forgeKey = newVal[0]['forge_key'];
				}
			},
		}
	},
	methods: {
		async partForgePurpose() {
			try {
				var purposes = {
					id: this.purpose,
					forge: this.forgeKey
				};
				const response = await axios.post('part-forge/purpose', purposes);
				if (response.data.success) {
          this.$emit('success', "Application Updated");
				}
			} catch (error) {
				this.$emit('error', error);
			}
		},
		autoSave() {
			this.partForgePurpose();
		},
	}
}
</script>