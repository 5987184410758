<template>
  <fieldset>
    <div class="mx-2 my-4">
      <file-upload-component :previewPart="previewPart" />
    </div>
  </fieldset>
</template>

<script>
import FileUploadComponent from '../../FileUpload/FileUploadComponent.vue';
export default {
  props: {
    previewPart: {
      type: [Object, Array],
      default: null,
    }
  },
  components: {
    FileUploadComponent
  },
};
</script>