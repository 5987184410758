<template>
  <div>
    <ul class="nav nav-tabs forgetabs" role="tablist">
      <li v-for="(tab, index) in tabs" 
      :key="index"
      class="nav-item forgelinks" @click="selectTab(index )">
        <a
          :class="['forgenav-link', { active: selectedIndex === index }]"
          href="#"
          role="tab"
          @click.prevent
        >
        <i :class=tab.icon></i> {{ tab.label }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div v-for="(tab, index) in tabs" :key="index" :class="['tab-pane', { active: selectedIndex === index }]" role="tabpanel">
        <slot :name="tab.name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: 0,
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    active: Boolean,
  },
  methods: {
    selectTab(index) {
      this.selectedIndex = index;
    },
  },
};
</script>

<style scoped>
ul.nav.nav-tabs.forgetabs {
  border: none;
  margin-left: 5px;
  margin-top: 12px;
  margin-bottom: 8px;
}
a.forgenav-link {
background-color: #c5c4c1;
border: 1px solid #95602f;
color: white;
padding: 8px 12px; /* Adjust padding as needed */
margin-right: 2px; /* Adjust margin as needed */
border-radius: 10px 10px 0 0;
}

a.forgenav-link.active {
  background-color: #fdf5ea;
  color: #660600;
}
</style>
