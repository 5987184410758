<template>
    <div>
        <div class="row">
            <template v-if="Array.isArray(Main)">
                <div>
                    <div class="card" id="preview_title">
                        <h3 class="mx-2 mt-1">Main</h3>
                    </div>
                    <div v-for="(item, index) in Main" class="card" id="part-forge-preview" :key="index">
                        <div v-for="(value, key) in item" :key="key"
                            v-if="key !== 'forge_session' && key !== 'Forge Key'">
                            <div class="card-body" id="part-forge-preview-body">
                                <h5>{{ formatKey(key) }}: </h5>
                                <h5 class="mx-1"> {{ formatValue(value) }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="filteredAttributes.length > 0">
                <div>
                    <div class="card mt-2" id="preview_title">
                        <h3 class="mx-2 mt-1">Attributes</h3>
                    </div>
                    <div class="row w-75 mx-2 my-2">
                        <div v-for="(item, index) in filteredAttributes" class="col-md-4 mb-2" :key="index">
                            <div class="card h-100">
                                <div class="card-body" id="part-forge-preview-body">
                                    <h5 class="card-title">ID: {{ item.id }}</h5>
                                    <p class="card-text mx-2">Value: {{ item.value }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>


            <template v-if="Array.isArray(Files)">
                <div>
                    <div class="card mt-2" id="preview_title">
                        <h3 class="mx-2 mt-1">Files</h3>
                    </div>
                    <div>
                        <div v-for="(item, index) in Files" :key="index">
                            <img :src="'/storage/' + item.location" alt="Thumbnail" class="thumbnail w-25">
                            <h5>{{ item.filename }}</h5>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        previewPart: {
            type: [Object, Array],
            default: null,
        }
    },
    data() {
        return {
            Main: [],
            Attributes: [],
            Files: [],
        };
    },
    computed: {
        filteredAttributes() {
            return this.Attributes.map(attr => ({
                id: attr.id,
                value: attr.value_varchar || attr.value_int || attr.value_bool || attr.value_decimal || 'N/A'
            })).filter(attr => attr.id !== null && attr.value !== 'N/A');
        }
    },
    watch: {
        previewPart: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal != null) {
                    if (Array.isArray(newVal) && newVal.length > 0) {
                        const part = { ...newVal[0] };
                        this.Attributes = part.part_attributes || [];
                        if (part.forge_session && part.forge_session.length > 0) {
                            this.Files = JSON.parse(part.forge_session[0]['part_data'])['files'] || [];
                        }
                        delete part.part_attributes;
                        delete part.forge_session;
                        this.Main = [part];
                    }
                }
            },
        },
    },
    methods: {
        formatKey(key) {
            if (typeof key !== 'string') return key;
            return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
        },
        formatValue(value) {
            if (value === null) return 'N/A';
            if (typeof value === 'string' && value.includes('T')) {
                return new Date(value).toLocaleString();
            }
            return value;
        }
    },
}
</script>
