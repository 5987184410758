<template>
    <div>
        <div v-if="errorMessage" class="alert alert-danger"><i class="fa fa-triangle-exclamation mx-2"></i>{{
            errorMessage
        }}</div>
        <div v-if="successMessage" class="alert alert-success"><i class="fa fa-circle-check mx-2"></i>{{ successMessage
            }}
        </div>

        <div v-if="ProductGroups.length > 0">
            <div v-if="currentStep == 1">
                <form @submit.prevent="saveStep4" class="card w-75 m-3">
                    <div class="d-flex flex-row m-2">
                        <div class="d-flex flex-column ">
                            <div class="d-flex flex-row flex-wrap">
                                <div class="d-flex my-1">
                                    <label class="align-self-lg-center">*Parent Company:</label>
                                    <div v-if="ParentCompanies.length > 0" class="d-flex">
                                        <select v-model="step4.parent_company_id" id="parent_company_id"
                                            name="parent_company_id" class="xxxlong">
                                            <option v-for="company in ParentCompanies" v-bind:value="company.id">{{
            company.parent_company_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="d-flex my-1">
                                    <label class="align-self-lg-center">*Brand:</label>
                                    <div v-if="brands.length > 0" class="d-flex">
                                        <select v-model="step4.brand_id" id="parent_company_id" name="parent_company_id"
                                            class="xxxlong">
                                            <option v-for="brand in brands" v-bind:value="brand.id">{{ brand.brand_name
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="d-flex my-1">
                                    <label class="align-self-lg-center">*Title:</label>
                                    <input required v-model="step4.title" name="title" placeholder="Title"
                                        class="xxxlong" />
                                </div>
                            </div>
                            <div class="d-flex my-1">
                                <label>Description:</label>
                                <textarea v-model="step4.description" name="description" placeholder="Description"
                                    class="w-50"></textarea>
                            </div>

                            <div>
                                <div>
                                    <div class="d-flex flex-row">
                                        <div class="d-flex">
                                            <label class="align-self-lg-center mr-2">Part Number Scheme ID:</label>
                                            <input v-model="step4.part_number_scheme_id" class="my-2 py-2" type="text"
                                                id="partNumberSchema" placeholder="Part Number Scheme ID">
                                        </div>
                                        <div class="d-flex">
                                            <label class="align-self-lg-center mx-2">Part Number Segment:</label>
                                            <input v-model="step4.part_number_segment_code" onchange="enableActive()"
                                                class="my-2 py-2" type="text" id="partNumberSegement"
                                                placeholder="Part Number Segment Code">
                                        </div>
                                        <div class="d-flex">
                                            <label class="align-self-lg-center mx-2">Sub-Brands:</label>
                                            <select v-model="step4.subbrand_id" class="my-2 py-2" required>
                                                <option disabled value="">Please select one</option>
                                                <option v-for="item in subBrands" :value="item.id">{{ item.subbrand_name
                                                    }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="d-flex my-1">
                                            <label class="align-self-lg-center">*Subgroup ID:</label>
                                            <input type="text" v-model="step4.subgroup_id" name="subgroup_id"
                                                placeholder="Subgroup ID" class="xxlong" />
                                        </div>
                                        <label class="align-self-lg-center mx-2">Active:</label>
                                        <input v-model="step4.active" class="my-2 py-2" type="checkbox" id="active"
                                            placeholder="Active">
                                        <label class="align-self-lg-center mx-2">Allow New Parts:</label>
                                        <input v-model="step4.allow_new_parts" class="my-2 py-2" type="checkbox"
                                            id="allow" placeholder="Allow New Parts">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-sm-end">
                        <button class="btn btn-light">
                            <i class="fa fa-floppy-disk"></i> Save Subgroup
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div v-else>
            Product Groups are needed to create a product subgroup.
        </div>
    </div>
</template>

<script>
export default {
    props: ['ProductGroups', 'ParentCompanies', 'step', 'PartSchemes', 'ProductGroupId', 'IsLimnocity'],
    data() {
        return {
            errorMessage: "",
            successMessage: "",
            currentStep: null,
            skipStep1: false,
            step1: {
                parent_company_id: null,
                title: "",
                description: "",
                brand_id: null,
            },
            newPartNumberSchemeID: null,
            subBrands: [],
            ExistingStep1: [],
            exitingSegmentCode: [],
            existingProductGroup: null,
            step4button: false,
            brands: [],
            part_id: null,
            step3Button: false,
            step3: {
                segmentCode: null,
                description: null,
                subbrand: null,
                title: null,
                active: null,
                newParts: null,
                newPartNumberSchemeID: null,
                brand_id: null,
                parent_company_id: null
            },
            step4: {
                parent_company_id: null,
                brand_id: null,
                part_id: null,
                subgroup_id: null,
                productgroup_id: null,
                part_number_segment_id: null,
                active: null,
                allow_new_parts: null,
                part_number_segment_code: null,
                subbrand_id: null,
                title: null,
                description: null,
                product_group: null,
            },
            suggestionsDisplay: false,
        }
    },
    watch: {
        ProductGroups: {
            immediate: true,
            deep: true,
            handler(newVal) {
                // Grab the passed in subbrands
                newVal.forEach(product_groups => {
                    if (product_groups.subbrand.subbrand_name != null) {
                        this.subBrands.push(product_groups.subbrand);
                    }
                });

                // Convert the array of subbrands to a Set to remove duplicates
                const uniqueSubBrandsSet = new Set(this.subBrands.map(subbrand => subbrand.subbrand_name));

                // Convert the Set back to an array
                this.subBrands = [...uniqueSubBrandsSet].map(subbrandName => {
                    // Find the subbrand object with the corresponding name
                    return this.subBrands.find(subbrand => subbrand.subbrand_name === subbrandName);
                });

                this.step1.parent_company_id = newVal[0].parent_company_id;
                this.step1.brand_id = newVal[0].brand_id;
                this.brands.push(newVal[0]['brand']);
            }
        },
        step1: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal.parent_company_id != null) {
                    this.step3.parent_company_id = newVal.parent_company_id;
                    this.step4.parent_company_id = newVal.parent_company_id;
                }
                if (newVal.brand_id != null) {
                    this.step3.brand_id = newVal.brand_id;
                    this.step4.brand_id = newVal.brand_id;
                }
            }
        },
        step3Button: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal != null) {
                    this.step3Button = true;
                }
            }
        },
        step3: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal.subbrand != null) {
                    this.step4.subbrand_id = newVal.subbrand;
                }
                if (newVal.segmentCode != null) {
                    this.step4.part_number_segment_code = newVal.segmentCode;
                }
                if (newVal.active != null) {
                    this.step4.active = newVal.active;
                }
                if (newVal.newParts != null) {
                    this.step4.allow_new_parts = newVal.newParts;
                }
                if (newVal.newPartNumberSchemeID != null) {
                    this.step4.part_number_segment_id = newVal.newPartNumberSchemeID;
                }
            }
        },
        newPartNumberSchemeID(newValue) {
            this.step3.newPartNumberSchemeID = newValue;
        },
        existingProductGroup(newValue) {
            this.step4button = true;
            this.step4.productgroup_id = newValue.id;
            this.step4.subbrand_id = newValue.subbrand['id'];
            this.step4.part_number_segment_code = newValue.part_number_segment_code;
            this.step4.active = newValue.active;
            this.step4.allow_new_parts = newValue.allow_new_parts;
            this.step4.part_number_scheme_id = newValue.part_number_scheme_id;
        },
        ExistingStep1(newValue) {
            this.skipStep1 = true;
            this.step3.parent_company_id = newValue.parent_company_id;
            this.step4.parent_company_id = newValue.parent_company_id;
            this.step3.brand_id = newValue.brand_id;
            this.step4.brand_id = newValue.brand_id;
        },
    },
    methods: {
        showAlert(message, type) {
            this[type] = message;
        },
        saveStep1() {
            axios.post(`/brands/part-number/subgroups/add`, this.step1)
                .then(response => {
                    if (sessionStorage.getItem('Subgroups_Create_Schema')) {
                        sessionStorage.removeItem('Subgroups_Create_Schema');
                        sessionStorage.setItem('Subgroups_Create_Schema', JSON.stringify(response.data.data));
                    } else {
                        sessionStorage.setItem('Subgroups_Create_Schema', JSON.stringify(response.data.data));
                    }
                    if (response.data.step != this.step) {
                        this.newPartNumberSchemeID = response.data.data.id;
                        this.currentStep = response.data.step;
                        this.subBrands = response.data.subBrands;
                        this.exitingSegmentCode = response.data.ownedGroups;
                    };
                    if (response.data.error) {
                        this.showAlert(response.data.error, 'errorMessage');
                    } else {
                        this.showAlert('Subgroup Part Number Schema Added.', 'successMessage');
                    }
                })
                .catch(error => {
                    this.showAlert('Error: ' + error, 'errorMessage');
                });
        },
        saveStep3() {
            axios.post(`/brands/part-group/add`, this.step3)
                .then(response => {

                    this.step4.productgroup_id = response.data.data.id;

                    if (sessionStorage.getItem('Newly_Created_Product_Group')) {
                        sessionStorage.removeItem('Newly_Created_Product_Group');
                        sessionStorage.setItem('Newly_Created_Product_Group', JSON.stringify(response.data.data));
                    } else {
                        sessionStorage.setItem('Newly_Created_Product_Group', JSON.stringify(response.data.data));
                    }
                    if (response.data.step != this.step) {
                        this.currentStep = "4";
                    };
                    if (response.data.error) {
                        this.showAlert(response.data.error, 'errorMessage');
                    } else {
                        this.showAlert('Product Group Added.', 'successMessage');
                    }
                })
                .catch(error => {
                    this.showAlert('Error: ' + error, 'errorMessage');
                });
        },
        goToStep3: function () {
            const part_id = document.getElementById("selectedPartID").value;
            this.part_id = part_id;
            this.step4.part_id = part_id;
            this.step4.brand_id = this.step1.brand_id;
            this.step4.parent_company_id = this.step1.parent_company_id;
            this.currentStep = 3;
        },
        saveStep4: function () {
            //Central Admin
            if (this.IsLimnocity) {
                axios.post(`/brands/product/subgroups/save`, this.step4)
                    .then(response => {
                        if (response.data.error) {
                            this.showAlert(response.data.error, 'errorMessage');
                        } else {
                            this.showAlert('Part added to product group.', 'finalsuccessMessage');
                            setTimeout(() => {
                                window.location.href = '/product-groups';
                            }, 1000);
                        }
                    })
                    .catch(error => {
                        this.showAlert('Error: ' + error, 'errorMessage');
                    });
            }
            else {
                //Tenant routes
                axios.post(`/tenant/brands/product/subgroups/add`, this.step4)
                    .then(response => {
                        if (response.data.error) {
                            this.showAlert(response.data.error, 'errorMessage');
                        } else {
                            this.showAlert('Part added to product group.', 'finalsuccessMessage');
                            setTimeout(() => {
                               window.location.href = '/tenant/brands/product-groups/' + response.data.data.brand_id;
                            }, 1000);
                        }
                    })
                    .catch(error => {
                        this.showAlert('Error: ' + error, 'errorMessage');
                    });
            }
        },
        goToStep4: function () {
            this.currentStep = 4;
        },
        displaySuggestions() {
            if (this.suggestionsDisplay == false) {
                this.suggestionsDisplay = true;
            }
        },
        goToStep2: function () {
            this.currentStep = 2;
        }
    },
    mounted() {
        this.currentStep = this.step;
        this.step4.productgroup_id = this.ProductGroupId;
    }
}
</script>