<template>
    <div>
        <div v-if="canDrag">
            <div v-if="group.part_attribute_group_members.length > 0">
                <draggable drag-class="dragClass" :options="{ forceFallback: true }" class="list-group my-4"
                    :list="reactiveGroup.part_attribute_group_members" group="member">
                    <div class="btn btn-secondary text-start attribute_member"
                        v-for="(element, index) in group.part_attribute_group_members" :key="element.name">
                        <i :class="icon"></i>
                        {{ element.sort_order }} : {{ element.autocare_p_a_d_b_part_attributes.PAName }}
                    </div>
                </draggable>
                <div v-if="canDrag" class="card-footer">
                <button @click="updateNonMemberGroup(group,group.part_attribute_group_members)" class="btn btn-outline-light"><i class="fa fa-floppy-disk"></i>
                    Update</button>
            </div>
            </div>
            <div v-else>
                <draggable drag-class="dragClass" :options="{ forceFallback: true }" class="list-group my-4"
                    :list="localPartAttributeGroup" group="member">
                    <div class="btn btn-secondary text-start attribute_member"
                        v-for="(element, index) in localPartAttributeGroup" :key="element.name">
                        <i :class="icon"></i>
                        {{ element.sort_order }} : {{ element.autocare_p_a_d_b_part_attributes.PAName }}
                    </div>
                </draggable>
                <div v-if="canDrag" class="card-footer">
                <button @click="updateNonMemberGroup(group,localPartAttributeGroup)" class="btn btn-outline-light"><i class="fa fa-floppy-disk"></i>
                    Update</button>
            </div>
            </div>
        </div>
        <div v-else>
            <div v-if="group.part_attribute_group_members.length > 0">
                <div :options="{ forceFallback: true }" class="list-group my-4"
                    :list="group.part_attribute_group_members" group="member">
                    <div class="btn btn-secondary text-start attribute_member"
                        v-for="(element, index) in group.part_attribute_group_members" :key="element.name">
                        <i :class="icon"></i>
                        {{ element.sort_order }} : {{ element.autocare_p_a_d_b_part_attributes.PAName }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div :options="{ forceFallback: true }" class="list-group my-4"
                    :list="localPartAttributeGroup" group="member">
                    <div class="btn btn-secondary text-start attribute_member"
                        v-for="(element, index) in localPartAttributeGroup" :key="element.name">
                        <i :class="icon"></i>
                        {{ element.sort_order }} : {{ element.autocare_p_a_d_b_part_attributes.PAName }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.dragClass {
    opacity: 1 !important;
    box-shadow: 0 .75em 2em rgba(0, 0, 0, .5), 0 .75em 3em rgba(0, 0, 0, .15);
}
</style>
<script>
import draggable from 'vuedraggable';

export default {
    props: {
        group: Object,
        canDrag: Boolean,
    },
    comments: {
        draggable,
    },
    data() {
        return {
            localPartAttributeGroup: [
            ],
            showEditItems: false,
            icon: null,
            reactiveGroup: {},
        }
    },
    watch: {
        group: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.reactiveGroup = { ...newVal };
            }
        },
        reactiveGroup: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if(newVal['part_attribute_group_members'].length > 0){
                    for(var i = 0; i < newVal['part_attribute_group_members'].length; i++){
                        if(newVal['part_attribute_group_members'][i]['sort_order'] != (i +1)){
                            newVal['part_attribute_group_members'][i]['sort_order'] = (i + 1);
                        }
                    }
                }
            }
        }
    },
    computed: {
        computedGroup(){
            return this.reactiveGroup;
        }
    },
    methods: {
        async updateNonMemberGroup(group,localPartAttributeGroup){
            axios.post('/part-attributes/addgroup', [group,localPartAttributeGroup])
                .then(response => {
                    if (response.data.error) {
                        this.$emit('message',['errorMessage', response.data.error]);
                    } else {
                        //this.reactiveGroup = JSON.stringify(response.data.data);
                        this.$emit('message',['successMessage', "Member update"]);
                    }
                })
                .catch(error => {
                    this.$emit('message',['Error', error]);
                });
        },
    },
    mounted() {
        this.showEditItems = this.edit;
        if (this.canDrag) {
            this.icon = "fa fa-up-down-left-right";
        }
        this.reactiveGroup = { ...this.group };
    }
}
</script>