<template>
    <div>
        <div v-for="priorite in uomPriorities" class="card">
            <h3 class="card-title mx-2 mt-2">{{ priorite[0].autocare_p_a_d_b_part_attributes.PAName }}</h3>
            <h5 class="card-title mx-2">{{ priorite[0].autocare_p_a_d_b_part_attributes.PADescr }}</h5>
        </div>
    </div>
</template>
<script>


export default {
    props: ['uomPriorities'],
}
</script>